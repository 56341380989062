import { ReactElement } from "react";
import { numberAscendingComparator } from "../../../utils/sort";
import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Link,
  Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  history?: BreadcrumbHistoryItem[];
  currentPage: string;
}

export interface BreadcrumbHistoryItem {
  name: string;
  relativePath: string;
  order: number;
  state?: any;
}

const Breadcrumbs = (props: BreadcrumbsProps): ReactElement => {
  const { history, currentPage, sx, ...other } = props;

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{ marginBottom: "1.5rem", ...sx }}
      {...other}
    >
      {history &&
        history.length > 0 &&
        history
          .sort((a, b) => numberAscendingComparator(a.order, b.order))
          .map((i) => (
            <Link
              variant="body2"
              key={i.relativePath}
              component={RouterLink}
              underline="hover"
              color="inherit"
              to={i.relativePath}
              state={i.state}
            >
              {i.name}
            </Link>
          ))}

      <Typography color="text.primary" variant="body2">
        {currentPage}
      </Typography>
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
export type { BreadcrumbsProps };
