import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";
import MaintenanceGuard from "guards/MaintenanceGuard";

type PossibleDisconnectReasons = "API_ERROR" | "MAINTENANCE_FLAG_SET" | "";

function validateDCReason(
  toValidate: string
): toValidate is PossibleDisconnectReasons {
  return ["", "API_ERROR", "MAINTENANCE_FLAG_SET"].includes(toValidate);
}

const DisconnectContext = createContext({
  disconnectState: undefined as boolean,
  disconnectReason: "" as PossibleDisconnectReasons,
  manageDisconnectState: (
    _disconnectState: boolean,
    _disconnectReason: PossibleDisconnectReasons
  ) => {}
});

const useDisconnectContext = () => useContext(DisconnectContext);

const DisconnectListener = () => {
  const { disconnectState, disconnectReason } = useDisconnectContext();

  useEffect(() => {
    // NOTE: Error boundaries don't catch Errors thrown in Promises (i.e.
    // axios returning 500), so we throw an error here that the boundary WILL catch

    if (disconnectState) {
      throw new Error(disconnectReason);
    }
  }, [disconnectState]);

  return null;
};

const DisconnectContextProvider = ({
  children
}: {
  children: ReactElement;
}) => {
  const [disconnectState, setDisconnectState] = useState<boolean | null>(null);
  const [disconnectReason, setDisconnectReason] =
    useState<PossibleDisconnectReasons>("");

  const manageDisconnectState = (
    disconnectState: boolean,
    disconnectReason: PossibleDisconnectReasons
  ) => {
    if (!validateDCReason(disconnectReason)) {
      throw new Error("[DisconnectContext] Invalid disconnect reason");
    }

    setDisconnectState(disconnectState);
    setDisconnectReason(disconnectReason);
  };

  return (
    <DisconnectContext.Provider
      value={{
        disconnectState,
        disconnectReason,
        manageDisconnectState
      }}
    >
      <MaintenanceGuard>{children}</MaintenanceGuard>
    </DisconnectContext.Provider>
  );
};

export { DisconnectContextProvider, DisconnectListener, useDisconnectContext };
