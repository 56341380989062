import { useEffect } from "react";
import { getConfig } from "./getConfig";
import gtm from "./lib/gtm";
import routes from "./routes";
import { useLocation, useRoutes } from "react-router-dom";
import useHotjar from "react-use-hotjar";
import type { FC } from "react";
import "./App.css";

const App: FC = () => {
  const location = useLocation();
  const { initHotjar } = useHotjar();
  const { hotjarId } = getConfig();
  const content = useRoutes(routes);

  useEffect(() => {
    initHotjar(hotjarId, 6);
  }, [initHotjar, hotjarId]);

  useEffect(() => {
    gtm.initialize();
  }, []);

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, [location]);

  return <>{content}</>;
};

export default App;
