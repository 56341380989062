import { removeItemLocalStorage } from "../utils/localStorage";
import { useAuth0 } from "@auth0/auth0-react";

function useLogout(): { logout: () => void } {
  const { logout } = useAuth0();
  const { user, isLoading: loadingUser } = useAuth0();
  return {
    logout: () => {
      if (user && !loadingUser) {
        removeItemLocalStorage("acrrm_companyId", user.sub);
        removeItemLocalStorage("acrrm_persistcompanyId", user.sub);
        removeItemLocalStorage("acrrm_personCompanyFunctions", user.sub);
        removeItemLocalStorage("acrrm_personFunctions", user.sub);
        removeItemLocalStorage("acrrm_accreditationApplication", user.sub);
        removeItemLocalStorage("acrrm_curriculumApplicationRotation", user.sub);
      }
      logout({ returnTo: window.location.origin });
      localStorage.removeItem("acrrm_becomeaccred_personaldetails");
      localStorage.removeItem("acrrm_becomeaccred_accreditationdetails");
      localStorage.removeItem("acrrm_becomeaccred_healthservicedetails");
      localStorage.removeItem("acrrm_becomeaccred_accountdetails");
      localStorage.removeItem("acrrm_becomeaccred_becomeaccred");
    }
  };
}

export { useLogout };
