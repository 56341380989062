import { mock } from "../lib/axios";
import { ApiResponse } from "../types/Api";
import { TrainingPostListItem } from "types/Api/TrainingPostListItem";

mock.onGet("/TrainingPost/GetAllTrainingPosts").reply(
  () =>
    new Promise((resolve) => {
      resolve([
        200,
        {
          message: "Training Posts found",
          result: [
            {
              id: 111,
              companyId: 11,
              companyName: "Apple and Pear Health Service",
              dualAccreditation: false,
              emergencyCare: false,
              mmm: "MMM 2",
              mmmId: 2,
              primaryCare: true,
              remediationFit: false,
              ruralAndRemote: true,
              secondaryCare: false,
              companyState: "QLD"
            } as TrainingPostListItem,
            {
              id: 112,
              companyId: 12,
              companyName: "Cannonvale Private Health Clinic",
              dualAccreditation: true,
              emergencyCare: false,
              mmm: "MMM 1",
              mmmId: 1,
              primaryCare: false,
              remediationFit: false,
              ruralAndRemote: false,
              secondaryCare: false,
              companyState: "QLD"
            } as TrainingPostListItem,
            {
              id: 113,
              companyId: 13,
              companyName: "Gisborne Medical Centre",
              dualAccreditation: true,
              emergencyCare: true,
              mmm: "MMM 3",
              mmmId: 3,
              primaryCare: false,
              remediationFit: false,
              ruralAndRemote: true,
              secondaryCare: false,
              companyState: "QLD"
            } as TrainingPostListItem,
            {
              id: 114,
              companyId: 14,
              companyName: "North Rockhampton General Practice",
              dualAccreditation: false,
              emergencyCare: false,
              mmm: "MMM2",
              mmmId: 2,
              primaryCare: true,
              remediationFit: false,
              ruralAndRemote: true,
              secondaryCare: false,
              companyState: "QLD"
            } as TrainingPostListItem,
            {
              id: 115,
              companyId: 15,
              companyName: "Orange Rainbow Health",
              dualAccreditation: true,
              emergencyCare: true,
              mmm: "MMM 3",
              mmmId: 3,
              primaryCare: true,
              remediationFit: true,
              ruralAndRemote: true,
              secondaryCare: true,
              companyState: "QLD"
            } as TrainingPostListItem,
            {
              id: 116,
              companyId: 16,
              companyName: "Patrick Street Clinic - Penguin",
              dualAccreditation: false,
              emergencyCare: false,
              mmm: "MMM 1",
              mmmId: 1,
              primaryCare: true,
              remediationFit: false,
              ruralAndRemote: false,
              secondaryCare: false,
              companyState: "NSW"
            } as TrainingPostListItem,
            {
              id: 117,
              companyId: 17,
              companyName: "Zucchini Private Medical",
              dualAccreditation: true,
              emergencyCare: true,
              mmm: "MMM 3",
              mmmId: 3,
              primaryCare: false,
              remediationFit: false,
              ruralAndRemote: true,
              secondaryCare: false,
              companyState: "NSW"
            } as TrainingPostListItem
          ] as TrainingPostListItem[]
        } as ApiResponse<TrainingPostListItem[]>
      ]);
    })
);
