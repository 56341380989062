import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import {
  getSection,
  isPreviouslyUploadedFile
} from "../components/atoms/formFields/FilePicker/helpers";
import accreditationQs from "../data/accreditationApplicationQuestions.json";
import supervisorQs from "../data/supervisorAccreditationApplicationQuestions.json";
import { getConfig } from "../getConfig";
import { useAxios } from "../lib/axiosFileUpload";
import { useCompanyContext } from "./companyContext";
import { AxiosInstance } from "axios";
import dayjs from "dayjs";
import type {
  FileHasMetadata,
  FileUploadRecord,
  NewOrPreviousFile
} from "../components/atoms/formFields/FilePicker/helpers";

const DocumentContext = createContext({
  axiosInstance: undefined as AxiosInstance,
  getSection: (_entityName: string) => {},
  selectEntityRecordId: (_entityName: string, _filePickerId: string): string =>
    "",
  uploadMetaCategoryTermSetId: null,
  uploadMetaSourceTermSetId: null,
  globalFilesList: [] as NewOrPreviousFile[],
  setGlobalFilesList: (
    _listOfFiles: NewOrPreviousFile[] | ((currState: NewOrPreviousFile[]) => {})
  ) => {},
  filesUploadState: [] as FileUploadRecord[],
  setFilesUploadState: (
    _currState:
      | FileUploadRecord[]
      | ((currState: FileUploadRecord[]) => FileUploadRecord[])
  ) => {},
  deriveFilesNotUploaded: () => [] as NewOrPreviousFile[],
  uploadFiles: (
    _newEntityRecordIds: {
      filePickerId: string;
      entityRecordId?: number;
    }[]
  ): Promise<void> => new Promise((resolve) => resolve()),
  addToGlobalFileList: (_incomingFiles: NewOrPreviousFile[]) => {},
  removeFromGlobalFileList: (
    _fileName: string,
    _timeDateUploaded: string
  ) => {},
  manageFileUploadRecords: (
    _action:
      | "CREATE"
      | "UPLOAD_STARTED"
      | "UPLOAD_SUCCESSFUL"
      | "UPLOAD_FAILED",
    _file?: File,
    _dateTimeUploaded?: string,
    _sharepointId?: string
  ) => {},
  entityRecordIds: [] as { filePickerId: string; entityRecordId?: number }[],
  setEntityRecordIds: (
    _entityRecordIds: { filePickerId: string; entityRecordId?: number }[]
  ) => {}
});

const useDocumentContext = () => useContext(DocumentContext);

const DocumentContextProvider = ({ children }: { children: ReactElement }) => {
  const { axiosInstance } = useAxios();
  const { currentCompanyId, personCompanyFunctions, personFunctions } =
    useCompanyContext();
  const { uploadMetaSourceTermSetId, uploadMetaCategoryTermSetId } =
    getConfig();
  const [entityRecordIds, setEntityRecordIds] = useState(
    [] as { filePickerId: string; entityRecordId?: number }[]
  );

  useEffect(() => {
    setEntityRecordIds([]);
  }, [currentCompanyId]);

  const selectEntityRecordId = (
    entityName: string,
    filePickerId: string, // Use this to find specific mapped id.
    newEntityRecordIds?: { filePickerId: string; entityRecordId?: number }[] // New record ids that haven't made it to state yet.
  ): string => {
    let ERI = null;
    if (newEntityRecordIds) {
      ERI = newEntityRecordIds.find(
        (i) => i.filePickerId === filePickerId
      )?.entityRecordId;
    } else if (entityRecordIds) {
      ERI = entityRecordIds.find(
        (i) => i.filePickerId === filePickerId
      )?.entityRecordId;
    }

    if (!ERI) {
      ERI =
        entityName === "Companies"
          ? currentCompanyId
          : personCompanyFunctions && personCompanyFunctions.length > 0
          ? personCompanyFunctions.find((el) => el.personId !== undefined)
              .personId
          : personFunctions.find((el) => el.personId !== undefined).personId;
    }

    if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
      console.log(
        `Selected entityRecordId ${ERI} from entityName ${entityName} and filePickerId ${filePickerId}`
      );
    }

    // make absolutely sure this is a string
    return "" + ERI;
  };

  const [globalFilesList, setGlobalFilesList] = useState<NewOrPreviousFile[]>(
    []
  );
  const [filesUploadState, setFilesUploadState] = useState<FileUploadRecord[]>(
    []
  );

  const addToGlobalFileList = (incomingFiles: NewOrPreviousFile[]) => {
    return setGlobalFilesList(() => {
      const dedupe = incomingFiles.filter((incomingFile) => {
        // PreviouslyUploadedFiles can be deduped by SharePoint ID
        return (
          globalFilesList.findIndex((el) => {
            if (isPreviouslyUploadedFile(incomingFile)) {
              if (!isPreviouslyUploadedFile(el)) return false;
              return el.clientFileId === incomingFile.clientFileId;
            }

            if (isPreviouslyUploadedFile(el)) return false;

            return (
              el.dateTimeUploaded === incomingFile.dateTimeUploaded &&
              el.file.name === incomingFile.file.name
            );
          }) === -1
        );
      });

      return [...globalFilesList, ...dedupe];
    });
  };

  const deriveFilesNotUploaded = (): NewOrPreviousFile[] => {
    let notUploaded = globalFilesList;
    notUploaded = notUploaded.filter((existingFile) =>
      isPreviouslyUploadedFile(existingFile) ? false : true
    ) as FileHasMetadata[];

    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.log("got rid of prev files", notUploaded);

    notUploaded = notUploaded
      // disregard files that have failed validation
      .filter((file: FileHasMetadata) => !file.errors);

    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.log("got rid of failed files", notUploaded);

    // disregard files that have already been uploaded (i.e. don't reupload)
    notUploaded = notUploaded.filter((file: FileHasMetadata) => {
      // check the file's metadata against the record's metadata
      const find = filesUploadState.findIndex((record: FileUploadRecord) => {
        return (
          // because setFileUploadState copies dateTimeUploaded from the
          // dropped file itself, we can use dateTimeUploaded to check if
          // a file has already been uploaded, even if the file has the
          // same name / size / last modified date / etc
          record.dateTimeUploaded === file.dateTimeUploaded &&
          // but we still want to make sure the filename is the same
          record.fileName === file.file.name
        );
      });

      window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
        console.log({
          fileMatchingUploadRecord: filesUploadState[find],
          hasFileID: filesUploadState[find].fileId !== undefined
        });

      return filesUploadState[find].fileId === undefined;
    });

    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.log("got rid of already uploaded files", notUploaded);

    return notUploaded;
  };

  /**
   * Composes payload for uploading files to the `/Files` endpoint.
   * @param file The file object to generate formData for.
   * @param entityName The name of the entity. Comes from the document upload
   *        config on a question.
   * @param questionId The long ID of a question. Comes from the question object
   *        itself.
   * @param numberOfFilesToUpload The length of the total collection of the
   *        files to upload.
   * @param section The section of the form the file was dropped on. Used in
   *        SharePoint.
   * @param docCategory The category of the document. Used in SharePoint.
   */
  const composeFormData = (
    file: FileHasMetadata,
    entityName: string,
    questionId: string,
    numberOfFilesToUpload: number,
    section: string,
    docCategory: string,
    entityRecordId: string
  ) => {
    const formData = new FormData();

    formData.append("EntityRecordId", entityRecordId);
    formData.append("EntityName", entityName);
    formData.append("FileTypeId", questionId);
    formData.append(
      "FileIndex",
      `${dayjs().format("YYYYMMDDHHmmss")}-${numberOfFilesToUpload}`
    );
    formData.append(
      "MetaDataFields",
      JSON.stringify([
        {
          // will not change
          fieldName: "AcrrmDocumentMetaSource",

          // based on the location of the picker
          // Partners.Portal.Health.Service.Profile
          fieldValue: `Partners.Portal.${section}`,

          // will change depending on environment - specified in getConfig()
          termSetId: uploadMetaSourceTermSetId
        },
        {
          // will not change
          fieldName: "AcrrmDocumentMetaCategory",

          // will change later
          fieldValue: docCategory,

          // will change depending on environment
          termSetId: uploadMetaCategoryTermSetId
        }
      ])
    );
    formData.append(
      "Fields",
      JSON.stringify([
        {
          fieldName: "AcrrmEntityRecordId",
          fieldValue: entityRecordId
        },
        {
          // TODO: can be human readable
          fieldName: "Title",
          fieldValue: questionId
        },
        {
          // saves the question id as metadata for the file - means it can be
          // filtered by question id in a later GET if need be
          fieldName: "AcrrmClientFileId",
          fieldValue: questionId
        }
      ])
    );

    // Appending the File last makes for nicer debugging :)
    formData.append("File", file.file);

    return formData;
  };

  /**
   * Takes in a long question ID and looks up the entity name and document
   * category.
   * @param id The long ID of a question.
   */
  const lookupDocConfig = useCallback(
    (questionID: string): { entityName: string; docCategory: string } => {
      let entityName = "";
      let docCategory = "";

      accreditationQs.accreditationApplicationQuestions.forEach((section) => {
        section.questions.forEach((el) => {
          if (el.responseType !== "document_upload") return;

          if (el.id === questionID) {
            entityName = el.documentUploadConfig.entityName;
            docCategory = el.documentUploadConfig.docCategory;
          }
        });
      });

      if (entityName !== "" && docCategory !== "") {
        return { entityName, docCategory };
      }

      supervisorQs.supervisorAccreditationApplicationQuestions.questions.find(
        (el) => {
          if (el.responseType !== "document_upload") return false;

          if (questionID.includes(el.id)) {
            entityName = el.documentUploadConfig.entityName;
            docCategory = el.documentUploadConfig.docCategory;
          }

          return questionID.includes(el.id);
        }
      );

      if (entityName !== "" && docCategory !== "") {
        return { entityName, docCategory };
      }

      if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
        if (entityName === "" || docCategory === "") {
          console.log(
            "missing either entityName or docCategory for this ID: ",
            questionID,
            { entityName, docCategory }
          );
        }
      }
    },
    [accreditationQs]
  );

  const selectAptifyEntityId = (entityName: string): string => {
    switch (entityName) {
      case "Persons":
        return "1006";

      case "Companies":
        return "897";

      default:
        throw new Error(
          `there is not an Aptify ID for supplied string: ${entityName}`
        );
    }
  };

  const uploadSingleFile = async (
    fileToBeUploaded: FileHasMetadata,
    fileIndex: number,
    newEntityRecordIds?: { filePickerId: string; entityRecordId?: number }[]
  ) => {
    const entityName = lookupDocConfig(
      fileToBeUploaded.targetQuestionId
    ).entityName;

    const docCategory = lookupDocConfig(
      fileToBeUploaded.targetQuestionId
    ).docCategory;

    const entityRecordId = selectEntityRecordId(
      entityName,
      fileToBeUploaded.filePickerId,
      newEntityRecordIds
    );

    const formData = composeFormData(
      fileToBeUploaded,
      entityName,
      fileToBeUploaded.targetQuestionId,
      fileIndex + 1,
      getSection(fileToBeUploaded.targetQuestionId),
      docCategory,
      entityRecordId
    );

    if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
      console.log({ fileToBeUploaded, entityName, docCategory, formData });
    }

    manageFileUploadRecords(
      "UPLOAD_STARTED",
      fileToBeUploaded.file,
      fileToBeUploaded.dateTimeUploaded
    );

    return await axiosInstance
      .request({
        method: "POST",
        url: "/Files",
        data: formData
      })
      .then((res) => {
        if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
          console.info("File upload successful:", res);
        }

        manageFileUploadRecords(
          "UPLOAD_SUCCESSFUL",
          fileToBeUploaded.file,
          fileToBeUploaded.dateTimeUploaded,
          res.data.result.fileId
        );
        return res.data.result.fileId;
      })
      .then(async (sharePointID) => {
        await axiosInstance
          .request({
            method: "POST",
            url: `/Entities/entityName/${entityName}/entityRecordId/${entityRecordId}`,
            data: [
              {
                entityRecordId: entityRecordId,
                entityName,
                entityId: selectAptifyEntityId(entityName),
                fileId: sharePointID,
                createdBy: "SYSTEM",
                category: docCategory
              }
            ]
          })
          .then((res) => {
            if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
              console.log({ res });
            }
          })
          .catch((err) => {
            if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
              console.log({ err });
            }
          });
      })
      .catch((err: Error) => {
        if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
          console.log("file upload error: ", err);
        }

        manageFileUploadRecords(
          "UPLOAD_FAILED",
          fileToBeUploaded.file,
          fileToBeUploaded.dateTimeUploaded
        );
      });
  };

  const uploadFiles = async (
    newEntityRecordIds?: { filePickerId: string; entityRecordId?: number }[]
  ) => {
    const filesNotUploaded = deriveFilesNotUploaded() as FileHasMetadata[];

    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.log({ globalFilesList, filesNotUploaded });

    try {
      const fileUploads = filesNotUploaded.map((file, i) =>
        uploadSingleFile(file, i, newEntityRecordIds)
      );

      await Promise.all(fileUploads);

      window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
        console.info("BING!");
    } catch (err) {
      window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
        console.error("[uploadFiles]", { err });
    }
  };

  const manageFileUploadRecords = (
    action: "CREATE" | "UPLOAD_STARTED" | "UPLOAD_SUCCESSFUL" | "UPLOAD_FAILED",
    file?: File,
    dateTimeUploaded?: string,
    sharepointId?: string
  ) => {
    if (action === "CREATE") {
      setFilesUploadState((currState): FileUploadRecord[] => {
        return [
          {
            fileName: file.name,
            dateTimeUploaded,
            isUploading: false
          },
          ...currState
        ];
      });
    }

    if (action === "UPLOAD_STARTED") {
      setFilesUploadState((currState): FileUploadRecord[] => {
        return currState.map((el) => {
          if (
            el.fileName === file.name &&
            el.dateTimeUploaded === dateTimeUploaded
          ) {
            return {
              ...el,
              isUploading: true
            };
          }

          return el;
        });
      });
    }

    if (action === "UPLOAD_SUCCESSFUL") {
      setFilesUploadState((currState): FileUploadRecord[] => {
        return currState.map((el) => {
          if (
            el.fileName === file.name &&
            el.dateTimeUploaded === dateTimeUploaded
          ) {
            return {
              ...el,
              isUploading: false,
              fileId: sharepointId
            };
          }

          return el;
        });
      });
    }

    if (action === "UPLOAD_FAILED") {
      setFilesUploadState((currState): FileUploadRecord[] => {
        return currState.map((el) => {
          if (
            el.fileName === file.name &&
            el.dateTimeUploaded === dateTimeUploaded
          ) {
            return {
              ...el,
              isUploading: false,
              axiosError: true
            };
          }

          return el;
        });
      });
    }
  };

  const removeFromGlobalFileList = (
    fileName: string,
    dateTimeUploaded?: string
  ) => {
    // fileToDelete is not previously uploaded
    setGlobalFilesList((currState): NewOrPreviousFile[] => {
      return currState.filter((el) => {
        if (isPreviouslyUploadedFile(el)) {
          if (el.fileName === fileName) {
            return false;
          }
          return true;
        }

        if (
          el.dateTimeUploaded === dateTimeUploaded &&
          el.file.name === fileName
        ) {
          return false;
        }

        return true;
      });
    });
  };

  useEffect(() => {
    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.log({ globalFilesList });
  }, [globalFilesList, window.localStorage]);

  return (
    <DocumentContext.Provider
      value={{
        axiosInstance,
        getSection,
        entityRecordIds,
        selectEntityRecordId,
        uploadMetaCategoryTermSetId,
        uploadMetaSourceTermSetId,
        globalFilesList,
        setGlobalFilesList,
        filesUploadState,
        setFilesUploadState,
        deriveFilesNotUploaded,
        uploadFiles,
        addToGlobalFileList,
        removeFromGlobalFileList,
        manageFileUploadRecords,
        setEntityRecordIds
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};

export { DocumentContextProvider, useDocumentContext };
