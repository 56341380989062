import { B2BConfig, B2BFeatureFlags } from "./featureFlags";

export interface AcrrmConfig extends B2BConfig, B2BFeatureFlags {
  apiBaseUrl: string;
  identityApiBaseUrl: string;
  uploadApiBaseUrl: string;
  apiScopes: string;
  name: string;
  hotjarId: number;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0AudienceUpload: string;
  auth0Database: string;
  azureConfigReadOnlyConnectionString: string;
  acrrmAppId: string;
  uploadMetaSourceTermSetId: string;
  uploadMetaCategoryTermSetId: string;
  tinymceApiKey: string;
  googleTagManagerContainerID: string;
  googleTagManagerEnvironmentAuth: string;
  featureEnableSupervisorTrainingReports: string;
  azureAppConfigEnv: "test" | "prod";
  googleMapsApiKey: string;
}

export function getConfig(): AcrrmConfig {
  const envToUse =
    window.localStorage.getItem("ACRRM_TARGET_ENVIRONMENT") ??
    window.location.hostname;

  const scopes =
    "openid profile email " +
    "acrrm.api.accreditationapplications.read acrrm.api.accreditationapplications.create acrrm.api.accreditationapplications.update " +
    "acrrm.api.company.read acrrm.api.company.create " +
    "acrrm.api.person.read acrrm.api.person.write " +
    "acrrm.api.personfunction.create acrrm.api.personfunction.read acrrm.api.personcompanyfunction.create acrrm.api.personcompanyfunction.read " +
    "acrrm.api.becomeaccredited.create acrrm.api.becomeaccredited.read " +
    "acrrm.api.registrar.read " +
    "acrrm.api.registrarclassregistration.read " +
    "acrrm.api.curriculumapplicationrequirement.read acrrm.api.curriculumapplicationrequirement.write " +
    "acrrm.api.placementhistory.read " +
    "acrrm.api.contacthistory.read " +
    "acrrm.api.logbook.read " +
    "acrrm.api.contactlog.read " +
    "acrrm.api.medicaleducator.read acrrm.api.medicaleducator.write " +
    "acrrm.api.file.write acrrm.api.taxonomy.read acrrm.api.contenttype.read " +
    "acrrm.api.file.read acrrm.api.file.delete acrrm.api.drive.read acrrm.api.drive.write acrrm.api.entities.write " +
    "acrrm.api.sessionreport.read " +
    "acrrm.api.topiccodelinks.read acrrm.api.topiccodelinks.write " +
    "acrrm.api.rotations.read acrrm.api.sessionreport.delete acrrm.api.tasks.read acrrm.api.tasks.delete acrrm.api.tasks.write " +
    "acrrm.api.postprofile.read acrrm.api.postprofile.write " +
    "acrrm.api.placementoffers.read acrrm.api.placementoffers.write " +
    "acrrm.api.placementpreferences.read " +
    "acrrm.api.supervisortrainingreport.read acrrm.api.supervisortrainingreport.write " +
    "acrrm.api.productcostdetails.read ";

  const azureConfigReadOnlyConnectionString =
    "Endpoint=https://acrrm-config.azconfig.io;Id=h4F+-lg-s0:E3M0KwUX4YUMLQ+UR03l;Secret=wG+iG5Qz8dwuJTSEF8Wdiu76bTKBxv0NLNA4+q7OjEI=";
  const acrrmAppId = "11c83ec5-c6ea-4ee7-be79-e9b78583680b";
  const tinymceApiId = "4yzs3sqcbzxgodj8conxwpcnclft1eb7yaqpmq5tj15nrml6";

  if (envToUse.includes("localhost")) {
    return {
      apiBaseUrl: "https://devapi.acrrm.org.au/business/v1.0",
      identityApiBaseUrl: "https://devapi.acrrm.org.au/identity/v1.0",
      uploadApiBaseUrl: "https://devapi.acrrm.org.au/upload/v1.0",
      name: "Local",
      hotjarId: 2481344,
      auth0Domain: "devauth.acrrm.org.au",
      auth0ClientId: "CHzJm0ADDBREd0MMtrflELpLMZQ5f4XD",
      auth0Audience: "https://devapib2b.acrrm.org.au",
      auth0AudienceUpload: "https://devapi.acrrm.org.au/upload",
      apiScopes: scopes,
      auth0Database: "Username-Password-Connect",
      azureConfigReadOnlyConnectionString: azureConfigReadOnlyConnectionString,
      featureFlagRemoveAccessName: "b2b-dev-remove-access-for-roles",
      configRemoveAccessName: "b2b-dev-remove-access-for-roles",
      acrrmAppId: acrrmAppId,
      uploadMetaSourceTermSetId: "a87d3526-5619-4a66-8510-ec133594fbf2",
      uploadMetaCategoryTermSetId: "d772bc31-5411-4842-8d21-4636f670b532",
      featureFlagEnableMaintenancePage: "b2b-dev-permanent-maintenance",
      configEnableMaintenancePage: "b2b-dev-permanent-maintenance",
      configRemovePageName: "b2b-dev-permanent-disable-page",
      featureFlagCreateSessionReports:
        "b2b-dev-temporary-create-session-reports",
      featureHideProgressionDocuments:
        "b2b-dev-permanent-progression-documents",
      tinymceApiKey: tinymceApiId,
      googleTagManagerContainerID: "GTM-PVJ5H6L",
      googleTagManagerEnvironmentAuth:
        "&gtm_auth=f2dP7i6aRZ6c-iIkogiRKg&gtm_preview=env-3&gtm_cookies_win=x",
      azureAppConfigEnv: "test",
      featureTurnCapacityCollectionOff:
        "b2b-test-hideplacementcapacitycollection",
      featureEnableSupervisorTrainingReports:
        "b2b-test-enable-supervisor-training-report",
      googleMapsApiKey: "AIzaSyCfzPHi-jgyWvWcPpmg2pMR-NxzCGbfN5A"
    };
  }

  switch (envToUse) {
    case "lemon-glacier-0d030a910.azurestaticapps.net": // TODO Update with dev url once configured correctly
    case "dev.partners.acrrm.org.au":
      return {
        apiBaseUrl: "https://devapi.acrrm.org.au/business/v1.0",
        identityApiBaseUrl: "https://devapi.acrrm.org.au/identity/v1.0",
        uploadApiBaseUrl: "https://devapi.acrrm.org.au/upload/v1.0",
        name: "Development",
        hotjarId: 2481411,
        auth0Domain: "devauth.acrrm.org.au",
        auth0ClientId: "CHzJm0ADDBREd0MMtrflELpLMZQ5f4XD",
        auth0Audience: "https://devapib2b.acrrm.org.au",
        auth0AudienceUpload: "https://devapi.acrrm.org.au/upload",
        apiScopes: scopes,
        auth0Database: "Username-Password-Connect",
        azureConfigReadOnlyConnectionString:
          azureConfigReadOnlyConnectionString,
        featureFlagRemoveAccessName: "b2b-dev-remove-access-for-roles",
        configRemoveAccessName: "b2b-dev-remove-access-for-roles",
        acrrmAppId: acrrmAppId,
        uploadMetaSourceTermSetId: "a87d3526-5619-4a66-8510-ec133594fbf2",
        uploadMetaCategoryTermSetId: "d772bc31-5411-4842-8d21-4636f670b532",
        featureFlagEnableMaintenancePage: "b2b-dev-permanent-maintenance",
        configEnableMaintenancePage: "b2b-dev-permanent-maintenance",
        configRemovePageName: "b2b-dev-permanent-disable-page",
        featureFlagCreateSessionReports:
          "b2b-dev-temporary-create-session-reports",
        featureHideProgressionDocuments:
          "b2b-dev-permanent-progression-documents",
        tinymceApiKey: tinymceApiId,
        googleTagManagerContainerID: "GTM-PVJ5H6L",
        googleTagManagerEnvironmentAuth:
          "&gtm_auth=f2dP7i6aRZ6c-iIkogiRKg&gtm_preview=env-3&gtm_cookies_win=x",
        azureAppConfigEnv: "test",
        featureTurnCapacityCollectionOff:
          "b2b-test-hideplacementcapacitycollection",
        featureEnableSupervisorTrainingReports:
          "b2b-test-enable-supervisor-training-report",
        googleMapsApiKey: "AIzaSyCfzPHi-jgyWvWcPpmg2pMR-NxzCGbfN5A"
      };
    case "polite-pond-000a42210.azurestaticapps.net": // TODO Update with uat url once configured correctly
    case "uat.partners.acrrm.org.au":
      return {
        apiBaseUrl: "https://uatapi.acrrm.org.au/business/v1.0",
        identityApiBaseUrl: "https://uatapi.acrrm.org.au/identity/v1.0",
        uploadApiBaseUrl: "https://uatapi.acrrm.org.au/upload/v1.0",
        name: "UAT",
        hotjarId: 2481387,
        auth0Domain: "uatauth.acrrm.org.au",
        auth0ClientId: "L8meoDRLySK93SxXC6jGEpDxvR4vilVE",
        auth0Audience: "https://uatapib2b.acrrm.org.au",
        auth0AudienceUpload: "https://uatapi.acrrm.org.au/upload",
        apiScopes: scopes,
        auth0Database: "Username-Password-Connect",
        azureConfigReadOnlyConnectionString:
          azureConfigReadOnlyConnectionString,
        featureFlagRemoveAccessName: "b2b-uat-remove-access-for-roles",
        configRemoveAccessName: "b2b-uat-remove-access-for-roles",
        acrrmAppId: acrrmAppId,
        uploadMetaSourceTermSetId: "3201237c-ed42-4b3d-8939-085d202336a7",
        uploadMetaCategoryTermSetId: "181897c3-cbbb-4cb8-8fcf-1bd83d2b5f42",
        featureFlagEnableMaintenancePage: "b2b-uat-permanent-maintenance",
        configEnableMaintenancePage: "b2b-uat-permanent-maintenance",
        configRemovePageName: "b2b-uat-permanent-disable-page",
        featureFlagCreateSessionReports:
          "b2b-uat-temporary-create-session-reports",
        featureHideProgressionDocuments:
          "b2b-uat-permanent-progression-documents",
        tinymceApiKey: tinymceApiId,
        googleTagManagerContainerID: "GTM-PVJ5H6L",
        googleTagManagerEnvironmentAuth:
          "&gtm_auth=Q6Gr3bTwCp0YnrDLw9j0SA&gtm_preview=env-4&gtm_cookies_win=x",
        azureAppConfigEnv: "test",
        featureTurnCapacityCollectionOff:
          "b2b-test-hideplacementcapacitycollection",
        featureEnableSupervisorTrainingReports:
          "b2b-test-enable-supervisor-training-report",
        googleMapsApiKey: "AIzaSyCfzPHi-jgyWvWcPpmg2pMR-NxzCGbfN5A"
      };
    case "orange-wave-0ba27fd10.azurestaticapps.net":
    case "uat2.partners.acrrm.org.au":
      return {
        apiBaseUrl: "https://uat2api.acrrm.org.au/business/v1.0",
        identityApiBaseUrl: "https://uat2api.acrrm.org.au/identity/v1.0",
        uploadApiBaseUrl: "https://uat2api.acrrm.org.au/upload/v1.0",
        name: "UAT2",
        hotjarId: 2705212,
        auth0Domain: "uatauth.acrrm.org.au",
        auth0ClientId: "H8AAebDYeeX4YsE5oRHiHwu3p7bm38E5",
        auth0Audience: "https://uat2apib2b.acrrm.org.au",
        auth0AudienceUpload: "https://uat2api.acrrm.org.au/upload",
        apiScopes: scopes,
        auth0Database: "Username-Password-Connect2",
        azureConfigReadOnlyConnectionString:
          azureConfigReadOnlyConnectionString,
        featureFlagRemoveAccessName: "b2b-uat2-remove-access-for-roles",
        configRemoveAccessName: "b2b-uat2-remove-access-for-roles",
        acrrmAppId: acrrmAppId,
        uploadMetaSourceTermSetId: "f6850e8b-6d51-486b-89a1-a3f6a7bb642b",
        uploadMetaCategoryTermSetId: "d2d935c7-dcbc-427a-853e-d42fe9b1e938",
        featureFlagEnableMaintenancePage: "b2b-uat2-permanent-maintenance",
        configEnableMaintenancePage: "b2b-uat2-permanent-maintenance",
        configRemovePageName: "b2b-uat2-permanent-disable-page",
        featureFlagCreateSessionReports:
          "b2b-uat2-temporary-create-session-reports",
        featureHideProgressionDocuments:
          "b2b-uat2-permanent-progression-documents",
        tinymceApiKey: tinymceApiId,
        googleTagManagerContainerID: "GTM-PVJ5H6L",
        googleTagManagerEnvironmentAuth:
          "&gtm_auth=Q6Gr3bTwCp0YnrDLw9j0SA&gtm_preview=env-4&gtm_cookies_win=x",
        azureAppConfigEnv: "test",
        featureTurnCapacityCollectionOff:
          "b2b-test-hideplacementcapacitycollection",
        featureEnableSupervisorTrainingReports:
          "b2b-test-enable-supervisor-training-report",
        googleMapsApiKey: "AIzaSyCfzPHi-jgyWvWcPpmg2pMR-NxzCGbfN5A"
      };
    default:
      // Production
      return {
        apiBaseUrl: "https://api.acrrm.org.au/business/v1.0",
        identityApiBaseUrl: "https://api.acrrm.org.au/identity/v1.0",
        uploadApiBaseUrl: "https://api.acrrm.org.au/upload/v1.0",
        name: "Production",
        hotjarId: 2739967,
        auth0Domain: "auth.acrrm.org.au",
        auth0ClientId: "aIzHQxxPSBHd6QXMq75rnQZHZzhkhPw6",
        auth0Audience: "https://api.acrrm.org.au/business",
        auth0AudienceUpload: "https://api.acrrm.org.au/upload",
        apiScopes: scopes,
        auth0Database: "Username-Password-Connect",
        azureConfigReadOnlyConnectionString:
          azureConfigReadOnlyConnectionString,
        featureFlagRemoveAccessName: "b2b-prod-remove-access-for-roles",
        configRemoveAccessName: "b2b-prod-remove-access-for-roles",
        acrrmAppId: acrrmAppId,
        uploadMetaSourceTermSetId: "2c4da540-d782-4e60-8287-36b8014d0a9b",
        uploadMetaCategoryTermSetId: "04094302-998b-4e3f-b4df-dfcab221c375",
        featureFlagEnableMaintenancePage: "b2b-prod-permanent-maintenance",
        configEnableMaintenancePage: "b2b-prod-permanent-maintenance",
        configRemovePageName: "b2b-prod-permanent-disable-page",
        featureFlagCreateSessionReports:
          "b2b-prod-temporary-create-session-reports",
        featureHideProgressionDocuments:
          "b2b-prod-permanent-progression-documents",
        tinymceApiKey: tinymceApiId,
        googleTagManagerContainerID: "GTM-PVJ5H6L",
        googleTagManagerEnvironmentAuth:
          "&gtm_auth=QMETMvNtwXeEyiOUGjXstg&gtm_preview=env-1&gtm_cookies_win=x",
        azureAppConfigEnv: "prod",
        featureTurnCapacityCollectionOff:
          "b2b-prod-hideplacementcapacitycollection",
        featureEnableSupervisorTrainingReports:
          "b2b-prod-enable-supervisor-training-report",
        googleMapsApiKey: "AIzaSyCfzPHi-jgyWvWcPpmg2pMR-NxzCGbfN5A"
      };
  }
}
