import { FC } from "react";
import { useCompanyContext } from "../../hooks/companyContext";
import { stringAscendingComparator } from "../../utils/sort";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const CompanyContextSelector: FC = () => {
  const { currentCompanyId, distinctCompanies, setCurrentCompanyId } =
    useCompanyContext();
  const navigate = useNavigate();
  const location = useLocation();

  if (distinctCompanies.length === 0) {
    return null;
  }

  return (
    <Select
      value={currentCompanyId ?? ""}
      onChange={(e: SelectChangeEvent<string>) => {
        setCurrentCompanyId(Number.parseInt(e.target.value));
        if (location.pathname !== "/dashboard") {
          navigate("/dashboard");
        }
      }}
    >
      {distinctCompanies
        .sort((a, b) => stringAscendingComparator(a.name, b.name))
        .map((i) => (
          <MenuItem key={i.value.toString()} value={i.value.toString()}>
            {i.name}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CompanyContextSelector;
