import type { NameValue } from "../../../types/NameValue";

const ACCREDITATION_CATEGORIES = {
  CORE_GENERALIST: {
    name: "Core Generalist",
    value: "Core Generalist",
    displayOrder: 1
  } as NameValue<string>,
  AST: {
    name: "AST",
    value: "AST",
    displayOrder: 2
  } as NameValue<string>,
  BOTH: {
    name: "Both",
    value: "Both",
    displayOrder: 3
  } as NameValue<string>
};

function getAccreditationCategoriesList(): NameValue<string>[] {
  return Object.entries(ACCREDITATION_CATEGORIES).map(
    (i) => i[1]
  ) as NameValue<string>[];
}

export { ACCREDITATION_CATEGORIES, getAccreditationCategoriesList };
