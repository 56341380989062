import stableSort from "./stableSort";

export function ascendingComparator(a: number, b: number) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

export function descendingComparator(a: number, b: number) {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
}

export function sortAscending(array: number[]): number[] {
  return stableSort(array, ascendingComparator);
}

export function sortDescending(array: number[]): number[] {
  return stableSort(array, descendingComparator);
}
