import React from "react";
import { ReactComponent as LogoSVG } from "../../atoms/logo.svg";
import { SxProps, Theme } from "@mui/system";
import type { ReactElement } from "react";

export interface LogoProps {
  viewBox?: string;
  height?: string;
  width?: string;
  sx?: SxProps<Theme>;
}

export const WideLogo = ({
  height = "52px",
  width,
  sx
}: LogoProps): ReactElement => {
  return (
    <LogoSVG
      width={width}
      height={height}
      // @ts-ignore
      sx={sx}
    />
  );
};

export default WideLogo;
