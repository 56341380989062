import { useMemo, useState } from "react";
import { getConfig } from "../getConfig";
import useIsMounted from "./useIsMounted";
import { AppConfigurationClient } from "@azure/app-configuration";

const client = new AppConfigurationClient(
  getConfig().azureConfigReadOnlyConnectionString
);

/**
 * Retrieves the specified feature flag from Azure App Configuration.
 * @param {string} flagKey App Config Feature Flag key
 * @returns the feature flag for the specified key
 */
const useFeatureFlag = (flagKey: string) => {
  const [enabled, setEnabled] = useState(false);
  const isMounted = useIsMounted();

  useMemo(async () => {
    if (!flagKey || !flagKey.trim().length) {
      throw new Error("A feature flag key must be supplied.");
    } else {
      const result = await client.getConfigurationSetting({
        key: `.appconfig.featureflag/${flagKey.trim()}`
      });
      if (result && typeof result === "object") {
        if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
          console.debug(
            "Feature: " + JSON.parse(result.value).id,
            JSON.parse(result.value).enabled
          );
        }
        if (isMounted()) {
          setEnabled(JSON.parse(result.value).enabled);
        }
      }
    }
  }, [flagKey]);

  return { enabled };
};

/**
 * Retrieves the specified configuration from Azure App Configuration.
 * @param {string} configKey App Config Key
 * @returns the configuration for the specified key
 */

function useConfiguration<Type>(configKey: string) {
  const [config, setConfig] = useState(null as Type | null);
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();

  useMemo(async () => {
    if (isMounted()) {
      setLoading(true);
    }
    if (!configKey || !configKey.trim().length) {
      setLoading(false);
      throw new Error("A config key must be supplied.");
    } else {
      const result = await client.getConfigurationSetting({
        key: configKey.trim()
      });
      if (result) {
        if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
          console.debug("Config: " + result.key, result.value);
        }
        if (result.value) {
          setConfig(JSON.parse(result.value) as Type);
        }
        setLoading(false);
      }
    }
  }, [configKey]);

  return { config, loading };
}

export { useConfiguration, useFeatureFlag };
