import { ReactElement, ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton } from "@mui/material";

const DialogTitleWithClose = (props: {
  children: ReactNode;
  onClose: () => void;
}): ReactElement => {
  const { children, onClose } = props;
  return (
    <DialogTitle>
      {children}
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default DialogTitleWithClose;
