import { Component, ReactNode } from "react";
import DownForMaintenance from "components/pages/errors/DownForMaintenance";

interface ErrorProps {
  children: ReactNode;
  isDisconnected?: boolean | null;
}

interface ErrorState {
  err: Error;
}

class MaintenanceBoundary extends Component<ErrorProps, ErrorState> {
  state = {
    err: null
  };

  static getDerivedStateFromError(error) {
    return {
      err: error
    };
  }

  public static dismissError() {
    return { err: null };
  }

  componentDidCatch(error, info) {
    window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true" &&
      console.info("[DisconnectErrorBoundary] caught an error: ", error, info);
  }

  render() {
    if (this.state.err) {
      return <DownForMaintenance />;
    }

    return this.props.children;
  }
}

export default MaintenanceBoundary;
