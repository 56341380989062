import { ReactNode } from "react";
import LoadingScreen from "../components/molecules/LoadingScreen";
import { useBecomingAccreditedContext } from "../hooks/becomeAccreditedContext";
import { useAuth0 } from "@auth0/auth0-react";
import { getTokenSilently } from "hooks/getTokenSilently";
import { Navigate } from "react-router-dom";

interface BecomeGuardProps {
  children: ReactNode;
}

const BecomeGuard = ({ children }: BecomeGuardProps) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { submittingBecomeAccredited } = useBecomingAccreditedContext();
  const { loading: loadingAccessToken } = getTokenSilently();

  if (isLoading || submittingBecomeAccredited || loadingAccessToken) {
    return <LoadingScreen />;
  }
  if (!isLoading && !submittingBecomeAccredited && !loadingAccessToken) {
    if (isAuthenticated || user) {
      return <Navigate to="/dashboard" />;
    }
  }

  return <>{children}</>;
};

export default BecomeGuard;
