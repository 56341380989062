import { ReactElement } from "react";
import { TableCell, TableCellProps } from "@mui/material";
import { grey } from "@mui/material/colors";

const StyledTableCell = (props: TableCellProps): ReactElement => {
  const { children } = props;
  return (
    <TableCell
      sx={{
        borderLeft: "1px solid",
        borderColor: grey[300]
      }}
      {...props}
    >
      {children}
    </TableCell>
  );
};

export default StyledTableCell;
