import { ReactElement } from "react";
import { SessionReportViewTaskHeading } from "../../registrars/Constants";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import { TableCell, TableContainer, TableHeaderCell } from "components/atoms";
import ProgressionTableCell from "components/molecules/ProgressionTableCell";
import dayjs from "dayjs";
import { useSessionReportTasks } from "hooks/useSessionReportTasks";
import { formatDate } from "utils/formatDateAndTime";
import { dateDescendingComparator } from "utils/sort";

const SessionReportTasks = (props: { id: string }): ReactElement => {
  const { id } = props;
  const { data: taskList, isValidating, error } = useSessionReportTasks(id);
  return (
    !isValidating &&
    !error &&
    taskList &&
    taskList.result.length > 0 && (
      <>
        <Typography variant="subtitle1">Tasks</Typography>
        <TableContainer sx={{ marginBottom: "2rem" }}>
          <Table
            sx={{
              width: "100%"
            }}
            aria-label="Session reports tasks."
          >
            <TableHead>
              <TableRow>
                {SessionReportViewTaskHeading.map((heading) => (
                  <TableHeaderCell id={heading} key={heading}>
                    {heading}
                  </TableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {taskList.result
                .sort((x, y) => dateDescendingComparator(x.dateDue, y.dateDue))
                .map((row) => {
                  const isOverdue =
                    formatDate(row.dateDue) !== "" &&
                    dayjs().isAfter(dayjs(row.dateDue), "day") &&
                    row.status !== "Complete";
                  return (
                    <TableRow key={row.id}>
                      <ProgressionTableCell text={row?.name} />
                      <ProgressionTableCell
                        text={
                          row?.assignedByExternal
                            ? row?.assignedByExternal
                            : row?.assignedBy
                        }
                      />
                      <ProgressionTableCell
                        text={
                          row?.assignedToExternal
                            ? row?.assignedToExternal
                            : row?.assignedTo
                        }
                      />
                      <ProgressionTableCell text={formatDate(row?.dateDue)} />
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={
                            row.priority === "High"
                              ? "red"
                              : row.priority === "Normal"
                              ? "secondary"
                              : ""
                          }
                        >
                          {row.priority}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={
                            isOverdue
                              ? "red"
                              : row.status === "Complete"
                              ? "green"
                              : row.status === "Pending"
                              ? "secondary"
                              : ""
                          }
                        >
                          {row.status}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  );
};
export default SessionReportTasks;
