import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from "react";
import type {
  BecomeAccreditedAccreditationDetails,
  BecomeAccreditedFinaliseAccount,
  BecomeAccreditedHealthServiceDetails,
  BecomeAccreditedPersonalDetails
} from "../types/becomeAccredited";

const BecomeAccreditedDataContext = createContext({
  personalDetails: null,
  accreditationDetails: null,
  healthServiceDetails: null,
  accountDetails: null,
  loadingBecomeAccredited: false,
  setBecomeAccredPersonalDetails: (
    _personalDetails: BecomeAccreditedPersonalDetails
  ) => {},
  setBecomeAccredAccreditationDetails: (
    _accreditationDetails: BecomeAccreditedAccreditationDetails
  ) => {},
  setBecomeAccredHealthServiceDetails: (
    _healthServiceDetails: BecomeAccreditedHealthServiceDetails
  ) => {},
  setBecomeAccredAccountDetails: (
    _accountDetails: BecomeAccreditedFinaliseAccount
  ) => {},
  setLoadingBecomeAccredited: (_loading: boolean) => {},
  clearBecomeAccreditedData: () => {}
});

const useBecomeAccreditedDataContext = () =>
  useContext(BecomeAccreditedDataContext);

const clearBecomeAccreditedData = () => {
  localStorage.removeItem("acrrm_becomeaccred_personaldetails");
  localStorage.removeItem("acrrm_becomeaccred_accreditationdetails");
  localStorage.removeItem("acrrm_becomeaccred_healthservicedetails");
  localStorage.removeItem("acrrm_becomeaccred_accountdetails");
  localStorage.removeItem("acrrm_becomeaccred_becomeaccred");
};

type BecomeAccreditedDataContextProviderProps = {
  children: ReactNode;
};

const BecomeAccreditedDataContextProvider: FC<
  BecomeAccreditedDataContextProviderProps
> = (props: BecomeAccreditedDataContextProviderProps) => {
  const { children } = props;

  const [loadingBecomeAccredited, setLoadingBecomeAccredited] = useState(
    localStorage.getItem("acrrm_becomeaccred_becomeaccred") === null
      ? false
      : JSON.parse(localStorage.getItem("acrrm_becomeaccred_becomeaccred"))
  );

  const [personalDetails, setBecomeAccredPersonalDetails] = useState(
    localStorage.getItem("acrrm_becomeaccred_personaldetails") === null
      ? (null as BecomeAccreditedPersonalDetails)
      : (JSON.parse(
          localStorage.getItem("acrrm_becomeaccred_personaldetails")
        ) as BecomeAccreditedPersonalDetails)
  );

  const [accreditationDetails, setBecomeAccredAccreditationDetails] = useState(
    localStorage.getItem("acrrm_becomeaccred_accreditationdetails") === null
      ? (null as BecomeAccreditedAccreditationDetails)
      : (JSON.parse(
          localStorage.getItem("acrrm_becomeaccred_accreditationdetails")
        ) as BecomeAccreditedAccreditationDetails)
  );

  const [healthServiceDetails, setBecomeAccredHealthServiceDetails] = useState(
    localStorage.getItem("acrrm_becomeaccred_healthservicedetails") === null
      ? (null as BecomeAccreditedHealthServiceDetails)
      : (JSON.parse(
          localStorage.getItem("acrrm_becomeaccred_healthservicedetails")
        ) as BecomeAccreditedHealthServiceDetails)
  );

  const [accountDetails, setBecomeAccredAccountDetails] = useState(
    localStorage.getItem("acrrm_becomeaccred_accountdetails") === null
      ? (null as BecomeAccreditedFinaliseAccount)
      : (JSON.parse(
          localStorage.getItem("acrrm_becomeaccred_accountdetails")
        ) as BecomeAccreditedFinaliseAccount)
  );

  useEffect(() => {
    if (personalDetails) {
      localStorage.setItem(
        "acrrm_becomeaccred_personaldetails",
        JSON.stringify(personalDetails)
      );
    }
  }, [personalDetails]);

  useEffect(() => {
    if (accreditationDetails) {
      localStorage.setItem(
        "acrrm_becomeaccred_accreditationdetails",
        JSON.stringify(accreditationDetails)
      );
    }
  }, [accreditationDetails]);

  useEffect(() => {
    if (healthServiceDetails) {
      localStorage.setItem(
        "acrrm_becomeaccred_healthservicedetails",
        JSON.stringify(healthServiceDetails)
      );
    }
  }, [healthServiceDetails]);

  useEffect(() => {
    if (accountDetails) {
      localStorage.setItem(
        "acrrm_becomeaccred_accountdetails",
        JSON.stringify(accountDetails)
      );
    }
  }, [accountDetails]);

  useEffect(() => {
    if (loadingBecomeAccredited === undefined) return;
    localStorage.setItem(
      "acrrm_becomeaccred_becomeaccred",
      loadingBecomeAccredited === null
        ? "false"
        : JSON.stringify(loadingBecomeAccredited)
    );
  }, [loadingBecomeAccredited]);

  return (
    <BecomeAccreditedDataContext.Provider
      value={{
        personalDetails,
        accreditationDetails,
        healthServiceDetails,
        accountDetails,
        loadingBecomeAccredited,
        setBecomeAccredPersonalDetails,
        setBecomeAccredAccreditationDetails,
        setBecomeAccredHealthServiceDetails,
        setBecomeAccredAccountDetails,
        setLoadingBecomeAccredited,
        clearBecomeAccreditedData
      }}
    >
      {children}
    </BecomeAccreditedDataContext.Provider>
  );
};

export {
  BecomeAccreditedDataContextProvider,
  clearBecomeAccreditedData,
  useBecomeAccreditedDataContext
};
