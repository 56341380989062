import { ReactNode } from "react";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import MaintenanceBoundary from "./MaintenanceBoundary";
import { getConfig } from "getConfig";
import { useDisconnectContext } from "hooks/disconnectContext";

interface MaintenanceGuardProps {
  children: ReactNode;
}

const MaintenanceGuard = ({ children }: MaintenanceGuardProps) => {
  const { manageDisconnectState } = useDisconnectContext();

  if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
    console.log("🚂 Now passing through MaintenanceGuard 🚂");
  }

  const config = getConfig();
  const { enabled: maintenancePageEnabled } = useFeatureFlag(
    config.featureFlagEnableMaintenancePage
  );

  if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
    console.log({ maintenancePageEnabled });
  }

  if (maintenancePageEnabled) {
    manageDisconnectState(true, "MAINTENANCE_FLAG_SET");
  }

  return <MaintenanceBoundary>{children}</MaintenanceBoundary>;
};

export default MaintenanceGuard;
