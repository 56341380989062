import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, Collapse, ListItem, useMediaQuery } from "@mui/material";
import grey from "@mui/material/colors/grey";
import Icon from "@mui/material/Icon";
import { useTheme } from "@mui/material/styles";
import type { ListItemProps } from "@mui/material";
import type { FC, ReactNode } from "react";

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  openprop: boolean;
  path?: string;
  title: string;
  drawerOpen?: boolean;
  component?: React.ElementType;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    openprop,
    path,
    title,
    drawerOpen,
    component,
    ...other
  } = props;
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(openprop);

  const isBetweenSMandLG = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (drawerOpen && depth > 0) {
    paddingLeft = 56 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          py: 0
        }}
        {...other}
      >
        <Button
          endIcon={
            open ? (
              <KeyboardArrowUp fontSize="small" />
            ) : (
              <KeyboardArrowDown fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={
            active ? (
              <Icon baseClassName="material-icons" sx={{ color: "white" }}>
                {icon}
              </Icon>
            ) : (
              <Icon baseClassName="material-icons">{icon}</Icon>
            )
          }
          sx={{
            borderRadius: "0",
            color: grey[700],
            fontWeight: "fontWeightMedium",
            justifyContent: "center",
            textAlign: "left",
            minWidth: "inherit",
            pl: `${paddingLeft}px`,
            pr: "16px",
            py: "12px",
            textTransform: "none",
            width: "100%",
            lineHeight: 1.5,

            ...(isBetweenSMandLG && {
              fontSize: "12px",
              flexDirection: "column",
              padding: "12px 4px",

              "& .MuiButton-startIcon": {
                margin: 0
              },

              "& .MuiButton-endIcon": {
                margin: 0
              }
            }),

            ...(depth === 0
              ? {
                  // first level
                  "&:hover": {
                    color: grey[700],
                    backgroundColor: grey[200],
                    "& .MuiButton-endIcon svg": {
                      color: grey[700]
                    }
                  },

                  ...(active && {
                    color: "background.default",
                    backgroundColor: "primary.main",
                    "&:hover": {
                      color: "background.default",
                      backgroundColor: "primary.main"
                    }
                  })
                }
              : {
                  // second level or more
                  backgroundColor: "primary.light",
                  color: "white",

                  "& .MuiButton-startIcon": {
                    display: "none"
                  },

                  "&:hover": {
                    backgroundColor: "primary.light",
                    textDecoration: "underline",
                    "& .MuiButton-endIcon svg": {
                      color: "primary.light"
                    }
                  },

                  ...(active && {
                    // color: "white",
                    backgroundColor: "primary.light",
                    textDecoration: "underline",
                    "&:hover": {
                      color: "background.default",
                      textDecoration: "underline",
                      backgroundColor: "primary.light"
                    }
                  })
                })
          }}
          variant="text"
        >
          <Box
            sx={{
              flexGrow: 1,
              whiteSpace: "initial",
              ...(isBetweenSMandLG ? { textAlign: "center" } : { pl: 0 })
            }}
          >
            {title}
          </Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0
      }}
    >
      <Button
        startIcon={
          active ? (
            <Icon baseClassName="material-icons" sx={{ color: "white" }}>
              {icon}
            </Icon>
          ) : (
            <Icon baseClassName="material-icons">{icon}</Icon>
          )
        }
        component={component}
        sx={{
          borderRadius: "0",
          color: grey[700],
          fontWeight: "fontWeightMedium",
          justifyContent: "center",
          textAlign: "left",
          minWidth: "inherit",
          pl: `${paddingLeft}px`,
          pr: "16px",
          py: "12px",
          textTransform: "none",
          width: "100%",
          lineHeight: 1.5,

          ...(isBetweenSMandLG && {
            fontSize: "12px",
            flexDirection: "column",
            padding: "12px 4px",

            "& .MuiButton-startIcon": {
              margin: 0
            }
          }),

          ...(depth === 0
            ? {
                // first level
                "&:hover": {
                  backgroundColor: grey[200],
                  "& .MuiButton-endIcon svg": {
                    color: "primary.light"
                  }
                },

                ...(active && {
                  color: "background.default",
                  backgroundColor: "primary.main",
                  "&:hover": {
                    color: "background.default",
                    backgroundColor: "primary.main"
                  }
                })
              }
            : {
                // second level or more
                backgroundColor: "primary.light",
                color: "white",

                "& .MuiButton-startIcon": {
                  display: "none"
                },

                "&:hover": {
                  backgroundColor: "primary.light",
                  textDecoration: "underline",
                  "& .MuiButton-endIcon svg": {
                    color: "primary.light"
                  }
                },

                ...(active && {
                  backgroundColor: "primary.light",
                  textDecoration: "underline",
                  "&:hover": {
                    color: "background.default",
                    textDecoration: "underline",
                    backgroundColor: "primary.light"
                  }
                })
              })
        }}
        variant="text"
        to={path || ""}
      >
        <Box
          sx={{
            flexGrow: 1,
            whiteSpace: "initial",
            ...(isBetweenSMandLG ? { textAlign: "center" } : { pl: 0 })
          }}
        >
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.defaultProps = {
  active: false,
  openprop: false
};

export default NavItem;
