import type { Module } from "../../types/Module";

export function findModule(moduleName: string, modules: Module[]): Module {
  if (!moduleName || !moduleName.trim()) {
    throw new Error(
      "moduleName cannot be null, undefined, empty or whitespace."
    );
  }
  if (!modules || modules.length === 0) {
    throw new Error("modules cannot be null, undefined or an empty array.");
  }

  const paths = moduleName.split("|");

  const module = modules.find((i) => i.name === paths[0]) as Module;

  if (paths.length === 1) {
    return module;
  }

  paths.splice(0, 1);
  return findModule(paths.join("|"), module.modules);
}
