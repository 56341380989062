import type { NameValue } from "../../types/NameValue";

const POSITIONS = {
  PRACTICE_MANAGER: {
    name: "Practice Manager",
    value: "Practice Manager"
  } as NameValue<string>,
  SUPERVISOR: {
    name: "Supervisor",
    value: "Supervisor"
  } as NameValue<string>,
  PRACTICE_PRINCIPAL_OWNER: {
    name: "Practice Principal/Owner",
    value: "Practice Principal/Owner"
  } as NameValue<string>,
  OPERATIONS_MANAGER: {
    name: "Operations Manager",
    value: "Operations Manager"
  } as NameValue<string>,
  CLINICAL_MANAGER: {
    name: "Clinical Manager",
    value: "Clinical Manager"
  } as NameValue<string>,
  ACADEMIC: {
    name: "Academic",
    value: "Academic"
  } as NameValue<string>
};

function getBecomeAccreditedPositionsList(): NameValue<string>[] {
  return [
    POSITIONS.PRACTICE_MANAGER,
    POSITIONS.SUPERVISOR,
    POSITIONS.PRACTICE_PRINCIPAL_OWNER,
    POSITIONS.OPERATIONS_MANAGER,
    POSITIONS.CLINICAL_MANAGER,
    POSITIONS.ACADEMIC
  ] as NameValue<string>[];
}

export { getBecomeAccreditedPositionsList, POSITIONS };
