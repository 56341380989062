import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

const WithSentryUser = ({ children }: any) => {
  const { user } = useAuth0();

  useEffect(() => {
    if (user === null || user === undefined) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser(user);
    }
  }, [user]);

  return children;
};

export default WithSentryUser;
