import dayjs from "dayjs";

export const formatDate = (date: Date): string => {
  let formattedDate = "";
  if (date && !date.toString().startsWith("1900-01-01")) {
    formattedDate = dayjs(date).format("DD/MM/YYYY");
  }
  return formattedDate;
};

export const formatTime = (date: Date): string => {
  let formattedTime = "";
  if (date && !date.toString().startsWith("1900-01-01")) {
    const time = date.toString().split("T");
    formattedTime =
      time[1] && time[1] !== "00:00:00" ? dayjs(date).format("hh:mm A") : "";
  }
  return formattedTime;
};

export const formatTime24h = (date: string): string => {
  let formattedTime = "";
  if (date && !date.startsWith("1900-01-01")) {
    formattedTime = dayjs(date).format("HH:mm");
  }
  return formattedTime;
};
