import { MESessionDetailsReportSummary } from "../types/Api/MESessionDetailsReportSummary";
import { getConfig } from "getConfig";
import useRequest from "lib/useRequest";
import type { ApiResponse } from "../types/Api";

function useMedicalEducatorSessionReportSummary(id: number | string) {
  return useRequest<ApiResponse<MESessionDetailsReportSummary[]>>(
    {
      url: `${
        getConfig().apiBaseUrl
      }/MedicalEducator/SessionHistory/Report/${id}`
    },
    {
      revalidateOnFocus: false
    }
  );
}

export default useMedicalEducatorSessionReportSummary;
