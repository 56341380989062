import Box from "@mui/material/Box";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SxProps, Theme } from "@mui/system";
import type { FC, ReactNode } from "react";

enum Variants {
  error,
  warning,
  info,
  success,
  default
}

type VariantsStrings = keyof typeof Variants;

export interface TaskListProps {
  variant?: VariantsStrings;
  link?: string;
  label?: string;
  sx?: SxProps<Theme>;
  text: string;
  onClick?: () => void;
  children?: ReactNode | undefined;
  source?: string;
}

const TaskList: FC<TaskListProps> = (props) => {
  const { text, link, label, variant, onClick, children, source } = props;

  const variantColor = {
    error: red[600],
    warning: orange[600],
    info: blue[600],
    success: green[600],
    default: "#3C5E5B"
  };

  return (
    <Box sx={{ width: "100%" }}>
      <nav aria-label={label}>
        <List>
          <ListItem
            disablePadding
            dense
            sx={{
              borderRadius: 2,
              backgroundColor:
                source === "NonAccreditation" ? "inherit" : grey[100],
              borderLeft:
                source === "NonAccreditation"
                  ? "inherit"
                  : `solid 8px ${variantColor[variant]}`,
              color: source === "NonAccreditation" ? "inherit" : grey[700],
              marginBottom: 1
            }}
          >
            <ListItemButton
              href={link}
              onClick={() => {
                if (onClick) onClick();
              }}
            >
              <ListItemText primary={text} />
            </ListItemButton>
            {children}
          </ListItem>
        </List>
      </nav>
    </Box>
  );
};

TaskList.defaultProps = {
  variant: "default",
  link: "#",
  label: "Task List",
  text: "Task item",
  sx: {}
};

export default TaskList;
