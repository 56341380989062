import { useContext } from "react";
import { SidebarContext } from "../../../hooks/sidebarContext";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import { Box, Hidden, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
  height: 80px;
  background-color: white;
  border-bottom:1px solid #E0E0E0; 
  padding: ${theme.spacing(0, 2)};
  right: 0;
  z-index: 5;
  position: fixed;
  justify-content: flex-end;
  width: 100%;
  boxSizing: border-box;
`
);

// eslint-disable-next-line no-undef
function Header(): JSX.Element {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <HeaderWrapper display="flex" alignItems="center" className="mui-fixed">
      <Box display="flex" alignItems="center"></Box>
      <Box display="flex">
        <Hidden lgUp>
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar} size="large">
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
