import { ReactElement } from "react";
import { Alert, AlertProps } from "@mui/material";

const NoItemsFound = (props: AlertProps): ReactElement => {
  const { children, ...other } = props;
  return (
    <Alert severity="info" sx={{ maxWidth: "600px" }} {...other}>
      {children}
    </Alert>
  );
};

export default NoItemsFound;
