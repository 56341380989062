/* eslint-disable prettier/prettier */
// HACK: two instances of prettier are giving me two different recommendations
// e.g. yarn prettier --write !== VSCode "Format on Save (with Prettier)"

import { createContext, FC, ReactNode, useContext, useState } from "react";

const BecomeAccreditedContext = createContext({
  submittingBecomeAccredited: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSubmittingBecomeAccredited: (_submitting: boolean) => {}
});

const useBecomingAccreditedContext = () => useContext(BecomeAccreditedContext);

type BecomingAccreditedContextProviderProps = {
  children: ReactNode;
};

const BecomeAccreditedContextProvider: FC<
  BecomingAccreditedContextProviderProps
> = (props: BecomingAccreditedContextProviderProps) => {
  const { children } = props;
  const [submittingBecomeAccredited, setSubmittingBecomeAccredited] =
    useState(false);

  return (
    <BecomeAccreditedContext.Provider
      value={{
        submittingBecomeAccredited,
        setSubmittingBecomeAccredited
      }}
    >
      {children}
    </BecomeAccreditedContext.Provider>
  );
};

export { BecomeAccreditedContextProvider, useBecomingAccreditedContext };
