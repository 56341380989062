import { useMemo, useState } from "react";
import { getConfig } from "../getConfig";
import useIsMounted from "./useIsMounted";
import { useAuth0 } from "@auth0/auth0-react";

const { apiScopes, auth0Audience } = getConfig();

const getTokenSilently = () => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const isMounted = useIsMounted();
  const { getAccessTokenSilently } = useAuth0();

  useMemo(async () => {
    if (isMounted()) {
      setLoading(true);
    }
    const token = await getAccessTokenSilently({
      scope: apiScopes,
      audience: auth0Audience
    })
      .then(
        (response) => response,
        () => null
      )
      .catch((err) => {
        if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
          console.error(err);
        }
      });
    if (token) {
      if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
        console.debug("Bearer Token: " + token);
      }
      if (isMounted()) {
        setToken(token);
      }
    }
    if (isMounted()) {
      setLoading(false);
    }
  }, [token]);
  return { token, loading };
};

export { getTokenSilently };
