import { useState } from "react";
import LoadingScreen from "../components/molecules/LoadingScreen";
import { useBecomingAccreditedContext } from "../hooks/becomeAccreditedContext";
import { useCompanyContext } from "../hooks/companyContext";
import * as modules from "../modules";
import { canAccessModule } from "../utils/roles";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";
import { Module } from "types/Module";
import type { FC, ReactNode } from "react";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated } = useAuth0();
  const {
    loading,
    personCompanyFunctions,
    currentCompanyId,
    personFunctions,
    accreditationApplication,
    curriculumApplicationRotation,
    haveAttemptedPCFLoading,
    haveAttemptedPFLoading
  } = useCompanyContext();
  const { submittingBecomeAccredited } = useBecomingAccreditedContext();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null as string);

  if (!loading) {
    if (!isAuthenticated && !submittingBecomeAccredited) {
      if (location.pathname !== requestedLocation) {
        setRequestedLocation(location.pathname);
      }
      return <Navigate to="/login" />;
    }
    if (
      isAuthenticated &&
      !submittingBecomeAccredited &&
      haveAttemptedPCFLoading &&
      haveAttemptedPFLoading
    ) {
      if (
        (modules.modules as Module[]).every(
          (i) =>
            !canAccessModule(
              i,
              personCompanyFunctions,
              personFunctions,
              currentCompanyId,
              accreditationApplication,
              curriculumApplicationRotation
            )
        )
      ) {
        if (location.pathname !== requestedLocation) {
          setRequestedLocation(location.pathname);
        }

        return <Navigate to="/no-access" />;
      }
    }

    // This is done so that in case the route changes by any chance through other
    // means between the moment of request and the render we navigate to the initially
    // requested route.
    if (requestedLocation && location.pathname !== requestedLocation) {
      setRequestedLocation(null as string);
      return <Navigate to={requestedLocation} />;
    }
    return <>{children}</>;
  }
  return <LoadingScreen />;
};

export default AuthGuard;
