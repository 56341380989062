import { ReactElement } from "react";
import { theme } from "@acrrm/designsystem";
import { CircularProgress, LinearProgress, Typography } from "@mui/material";

type LoadingProps = {
  message?: string;
};

const Loading = (props: LoadingProps): ReactElement => {
  const { message } = props;

  if (message) {
    return (
      <div style={{ width: "260px" }}>
        <LinearProgress />
        <Typography variant="body2" color={theme.palette.primary.main}>
          {message}
        </Typography>
      </div>
    );
  }
  return <CircularProgress />;
};

export default Loading;
export type { LoadingProps };
