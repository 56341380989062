import { mock } from "lib/axios";
import {
  TrainingPostCompanyBusinessHoursResponse,
  TrainingPostCompanyResponse,
  TrainingPostDetailResponse,
  TrainingPostEducationActivityResponse,
  TrainingPostPersonResponse,
  TrainingPostPracticeSpecialSkillsResponse,
  TrainingPostResponse
} from "types/Api/TrainingPost";

mock.onGet(new RegExp("/TrainingPost/GetTrainingPostDetail/*")).reply(
  () =>
    new Promise((resolve) =>
      resolve([
        200,
        {
          message: "Training Post Found",
          result: {
            trainingPostResponse: {
              id: 111,
              category: "Core Generalist Training",
              dualAccreditation: true,
              ruralAndRemote: true,
              primaryCare: true,
              secondaryCare: true,
              emergencyCare: true,
              numberOfHospitalBeds: 1,
              numberOfAcuteBeds: 2,
              numberOfNursingHomeBeds: 3
            } as TrainingPostResponse,
            trainingPostCompanyResponse: {
              id: 11,
              name: "Apple and Pear Health Service",
              addressLine1: "24 Lyttleton St",
              addressLine2: null,
              addressLine3: null,
              addressLine4: null,
              city: "Launceston",
              state: "TAS",
              postCode: "7250",
              modifiedMonash: "MMM 2",
              webSite: "https://www.acrrm.org.au/",
              latitude: -41.433690230992134,
              longitude: 147.14929077116474,
              extras: "Extra stuff about the company",
              vmo: true,
              townInformation:
                "<b>Town info is...</b><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In venenatis justo at elit facilisis, vel dignissim metus tempor. Nunc maximus enim eget dui malesuada, a vulputate arcu consectetur. Nulla dolor massa, eleifend eu leo eu, consequat consequat mi. Nulla maximus auctor arcu sit amet malesuada. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer et ante fringilla, porttitor leo in, tempor purus. Vivamus vehicula volutpat convallis. Proin auctor semper diam ac condimentum. Quisque in ligula tempor, molestie tellus quis, venenatis velit. Morbi accumsan, purus at feugiat tempor, sem dolor tincidunt libero, eu dignissim justo purus ornare sem. Morbi placerat ac tortor id pellentesque. Nam euismod ac diam aliquet dapibus. Nulla venenatis diam eget nulla pellentesque dignissim. <b>In dignissim convallis ante eget euismod.</b></p>",
              transportOptions:
                "alert('Hello!');<i>Transport options are: </i><ul><li>Bus</li><li>Train</li><li>Car - parking onsite</li></ul>",
              nursingArea:
                "<u>Nursing area info is...</u><br /><br /><table border='solid black 1px' margin='8px'><b><tr><td><b>Heading 1</b></td><td>Info about heading 1</td></tr><tr><td><b>Heading 2</b></td><td>Info about heading 2</td></tr><tr><td><b>Heading 3<b/></td><td>Info about heading 3</td></tr></table>",
              alliedHealth: true,
              practiceInformation:
                "<b>🏘️ Practice info is...</b><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In venenatis justo at elit facilisis, vel dignissim metus tempor. Nunc maximus enim eget dui malesuada, a vulputate arcu consectetur. Nulla dolor massa, eleifend eu leo eu, consequat consequat mi. Nulla maximus auctor arcu sit amet malesuada. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer et ante fringilla, porttitor leo in, tempor purus. Vivamus vehicula volutpat convallis. Proin auctor semper diam ac condimentum. Quisque in ligula tempor, molestie tellus quis, venenatis velit. Morbi accumsan, purus at feugiat tempor, sem dolor tincidunt libero, eu dignissim justo purus ornare sem. Morbi placerat ac tortor id pellentesque. Nam euismod ac diam aliquet dapibus. Nulla venenatis diam eget nulla pellentesque dignissim. In dignissim convallis ante eget euismod.</p>" +
                "<p><i>Aenean tortor erat, fermentum ut vehicula non, blandit vitae erat. Nullam suscipit auctor urna, et pretium sapien mollis non. Etiam laoreet nulla et risus tincidunt, id auctor turpis interdum. In hac habitasse platea dictumst. Donec rutrum gravida tortor non accumsan. Etiam placerat ex diam, et posuere ex bibendum in. Morbi sollicitudin placerat nulla vulputate ornare. In mattis sollicitudin dolor. Fusce ornare ipsum at justo consectetur, a fermentum tortor ultrices. In consequat malesuada maximus. Suspendisse volutpat sed nibh sit amet fringilla. Aliquam erat volutpat.</i></p>"
            } as TrainingPostCompanyResponse,
            trainingPostCompanyBusinessHoursResponse: [
              {
                day: "Monday",
                openingTime: "2023-07-05T08:00:00",
                closingTime: "2023-07-05T17:00:00"
              },
              {
                day: "Tuesday",
                openingTime: "2023-07-05T08:00:00",
                closingTime: "2023-07-05T17:00:00"
              },
              {
                day: "Wednesday",
                openingTime: "2023-07-05T08:00:00",
                closingTime: "2023-07-05T17:00:00"
              },
              {
                day: "Thursday",
                openingTime: "2023-07-05T09:00:00",
                closingTime: "2023-07-05T20:00:00"
              },
              {
                day: "Friday",
                openingTime: "2023-07-05T08:00:00",
                closingTime: "2023-07-05T17:00:00"
              },
              {
                day: "Saturday",
                openingTime: "2023-07-05T08:30:00",
                closingTime: "2023-07-05T12:00:00"
              }
            ] as TrainingPostCompanyBusinessHoursResponse[],
            trainingPostPracticeSpecialSkillsResponse: [
              {
                skill: "Chronic Disease Management",
                other: ""
              },
              {
                skill: "Other",
                other: "Super special other skill"
              }
            ] as TrainingPostPracticeSpecialSkillsResponse[],
            trainingPostEducationActivities: [
              {
                activityType: "Case Review",
                howOften: "Weekly",
                duration: "1 hour",
                deliveredBy: "Principal Supervisor"
              },
              {
                activityType: "Clinical Audit",
                howOften: "Fortnightly",
                duration: "3 hours",
                deliveredBy: "Supervisor"
              },
              {
                activityType: "Ward Rounds",
                howOften: "Daily",
                duration: "30 min",
                deliveredBy: "Shift Supervisor"
              },
              {
                activityType: "Chart Reviews",
                howOften: "Twice daily",
                duration: "1 hour 30 min",
                deliveredBy: "Registrar"
              }
            ] as TrainingPostEducationActivityResponse[],
            personPracticeManagers: [
              {
                firstName: "Joe",
                lastName: "Joeson"
              }
            ] as TrainingPostPersonResponse[],
            personGPSupervisors: [
              {
                firstName: "Albert",
                lastName: "Smith"
              },
              {
                firstName: "Sarah",
                lastName: "Adams"
              },

              {
                firstName: "Jim",
                lastName: "Jones"
              }
            ] as TrainingPostPersonResponse[],
            perseonGPSupervisors: null,
            personSupportStaff: [
              {
                firstName: "Jenny",
                lastName: "James"
              },
              {
                firstName: "Penny",
                lastName: "Cooper"
              }
            ] as TrainingPostPersonResponse[]
          } as TrainingPostDetailResponse
        }
      ])
    )
);
