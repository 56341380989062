import { PersonCompanyFunction } from "../../types/Api";

function isSupervisor(
  personCompanyFunctions: PersonCompanyFunction[],
  currentCompanyId: number
): boolean {
  const isSupervisor = (currentCompanyId &&
    personCompanyFunctions.some(
      (i) =>
        i.companyRecordId === currentCompanyId &&
        i.functionName.toLowerCase().trim() === "supervisor"
    )) as boolean;

  return isSupervisor;
}

function isPrincipalSupervisor(
  personCompanyFunctions: PersonCompanyFunction[],
  currentCompanyId: number
): boolean {
  const isPrincipalSupervisor = (currentCompanyId &&
    personCompanyFunctions.some(
      (i) =>
        i.companyRecordId === currentCompanyId &&
        i.functionName.toLowerCase().trim() === "principal supervisor"
    )) as boolean;

  return isPrincipalSupervisor;
}

function isPracticeManager(
  personCompanyFunctions: PersonCompanyFunction[],
  currentCompanyId: number
): boolean {
  const isPracticeManager = (currentCompanyId &&
    personCompanyFunctions.some(
      (i) =>
        i.companyRecordId === currentCompanyId &&
        i.functionName.toLowerCase().trim() === "practice manager"
    )) as boolean;

  return isPracticeManager;
}

function isNonAcrrmRole(
  personCompanyFunctions: PersonCompanyFunction[],
  currentCompanyId: number
): boolean {
  const isPracticeManager = (currentCompanyId &&
    personCompanyFunctions.some(
      (i) =>
        i.companyRecordId === currentCompanyId &&
        (i.functionName.toLowerCase().trim() === "practice manager" ||
          i.functionName.toLowerCase().trim() === "principal supervisor" ||
          i.functionName.toLowerCase().trim() === "supervisor")
    )) as boolean;

  return isPracticeManager;
}

export {
  isNonAcrrmRole,
  isPracticeManager,
  isPrincipalSupervisor,
  isSupervisor
};
