import { FC } from "react";
import { getConfig } from "../../getConfig";
import history from "../../lib/history";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

export interface Auth0ProviderWrapperProps {
  // eslint-disable-next-line no-undef
  children: React.ReactNode;
}

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const Auth0ProviderWrapper: FC<Auth0ProviderWrapperProps> = (
  props: Auth0ProviderWrapperProps
) => {
  const config = getConfig();
  const { children } = props;

  const authConfig = {
    domain: config.auth0Domain,
    clientId: config.auth0ClientId,
    redirectUri: `${window.location.origin}/dashboard`,
    onRedirectCallback,
    useRefreshTokens: true,
    audience: config.auth0Audience,
    scope: config.apiScopes,
    cacheLocation: "localstorage"
  } as Auth0ProviderOptions;

  return <Auth0Provider {...authConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderWrapper;
