import { createContext, ReactNode, useState } from "react";

type SidebarContext = { sidebarToggle: any; toggleSidebar: () => void };

// eslint-disable-next-line no-redeclare
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

type Props = {
  children: ReactNode;
};

// eslint-disable-next-line no-undef
export function SidebarProvider({ children }: Props): JSX.Element {
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  return (
    <SidebarContext.Provider value={{ sidebarToggle, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
}
