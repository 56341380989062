import React from "react";
import App from "./App";
import Auth0ProviderWrapper from "./components/molecules/Auth0ProviderWrapper";
import WithSentryUser from "./components/molecules/WithSentryUser";
import { getConfig } from "./getConfig";
import { BecomeAccreditedContextProvider } from "./hooks/becomeAccreditedContext";
import { CompanyContextProvider } from "./hooks/companyContext";
import { DocumentContextProvider } from "./hooks/documentContext";
import { SidebarProvider } from "./hooks/sidebarContext";
import reportWebVitals from "./reportWebVitals";
import { theme } from "@acrrm/designsystem";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BecomeAccreditedDataContextProvider } from "hooks/becomeAccreditedDataContext";
import { DisconnectContextProvider } from "hooks/disconnectContext";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./__mocks__";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env
    .REACT_APP_SENTRY_SAMPLERATE as unknown as number,
  environment: getConfig().name,
  release: process.env.REACT_APP_RELEASE
});

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Auth0ProviderWrapper>
          <BrowserRouter>
            <DisconnectContextProvider>
              <SidebarProvider>
                <BecomeAccreditedContextProvider>
                  <BecomeAccreditedDataContextProvider>
                    <CompanyContextProvider>
                      <DocumentContextProvider>
                        <WithSentryUser>
                          <App />
                        </WithSentryUser>
                      </DocumentContextProvider>
                    </CompanyContextProvider>
                  </BecomeAccreditedDataContextProvider>
                </BecomeAccreditedContextProvider>
              </SidebarProvider>
            </DisconnectContextProvider>
          </BrowserRouter>
        </Auth0ProviderWrapper>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
