import { FC } from "react";
import Page from "../../templates/Page";
import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";

const Unauthorized: FC = () => {
  const { isAuthenticated } = useAuth0();

  const heading = <Typography variant="h3">403: Unauthorized</Typography>;

  if (isAuthenticated) {
    return <Page currentPath="">{heading}</Page>;
  }

  return heading;
};

export default Unauthorized;
