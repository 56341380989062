import { ReactElement } from "react";
import useMedicalEducatorSessionReportSummary from "../../hooks/useMESessionDetailsReportSummary";
import * as modules from "../../modules";
import { formatDate, formatTime } from "../../utils/formatDateAndTime";
import { createMarkup } from "../../utils/renderHTML";
import Breadcrumbs from "../atoms/RegistrarBreadcrumbs";
import SessionReportTasks from "./reports/sessionReports/SessionReportTasks";
import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import PageBreadcrumbs from "components/molecules/breadcrumbs/PageBreadcrumbs";
import Page from "components/templates/Page";
import { useNavigate } from "react-router-dom";
import "../../index.css";

const SessionReport = (props: {
  id: string;
  sideBar: string;
}): ReactElement => {
  const { id, sideBar } = props;
  const navigate = useNavigate();
  const { data: summary } = useMedicalEducatorSessionReportSummary(id);
  const detailModule = modules.modules.find((i) => i.name === sideBar);
  return (
    <Page currentPath={detailModule.path}>
      <div>
        <Box sx={{ display: "flex" }}>
          {sideBar === "Registrars" && <Breadcrumbs />}
          {sideBar === "Reports" && (
            <PageBreadcrumbs
              currentPage="ME Session Report"
              history={[
                {
                  name: "Reports - Session Reports",
                  relativePath: "/reports/session-reports",
                  order: 0
                }
              ]}
            />
          )}
          &nbsp;
          <Typography
            variant="body2"
            sx={{ paddingTop: "2px", marginBottom: "1rem" }}
          >
            {" - "}
            {summary.result[0].personName}
            {" - "}
            {formatDate(summary.result[0].appointmentDateTime)}{" "}
            {formatTime(summary.result[0].appointmentDateTime)}
          </Typography>
        </Box>
        {sideBar === "Registrars" && (
          <Typography variant="h1">Training Plan &amp; Progression</Typography>
        )}

        <Box
          sx={{
            display: "flex",
            alignContent: "baseline",
            marginBottom: "1rem"
          }}
        >
          <Typography variant="h2">ME Session Report</Typography>
          <Grid>
            <Grid item>
              <IconButton>
                <Download onClick={() => print()}></Download>
              </IconButton>
            </Grid>
          </Grid>

          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              sideBar === "Session Reports"
                ? navigate("/session-reports")
                : navigate(-1)
            }
          >
            Back
          </Button>
        </Box>
        <Box sx={{ marginRight: "8rem" }}>
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "1rem",
                marginTop: "rem"
              }}
            >
              <Typography variant="body2">
                <strong>Registrar Name:</strong> {summary.result[0].personName}{" "}
                &nbsp;&nbsp; | &nbsp;&nbsp;
                <strong>Pathway:</strong>{" "}
                {summary.result[0].curriculumDefinition}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "1rem",
                marginTop: "rem"
              }}
            >
              <Typography variant="body2">
                <strong>Session Type:</strong>
                &nbsp;{summary.result[0].typeName} &nbsp;&nbsp;|&nbsp;&nbsp;
                <strong>Appointment Date & Time:</strong>{" "}
                {formatDate(summary.result[0].appointmentDateTime)}{" "}
                {formatTime(summary.result[0].appointmentDateTime)}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "1rem",
                marginTop: "0.5rem"
              }}
            >
              <Typography variant="body2">
                <strong>Training Start Date: </strong>
                {formatDate(summary.result[0].trainingStartDate)}
                &nbsp; &nbsp;|&nbsp;&nbsp;
                <strong>Training Officer: </strong>
                {summary.result[0].accountManagerName}
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                <strong> Medical Educator: </strong>
                {summary.result[0].submittedByFirstName}{" "}
                {summary.result[0].submittedByLastName}
              </Typography>
            </div>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="h3">Training Officer Comments</Typography>
              <Typography variant="body2">
                {summary.result[0].summary}
              </Typography>
            </Box>

            <Typography variant="h3">Medical Educator Comments</Typography>

            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="subtitle1">General</Typography>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    summary.result[0].general
                  )}
                ></div>
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="subtitle1">Training</Typography>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  summary.result[0].training
                )}
              ></div>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="subtitle1">Education</Typography>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  summary.result[0].education
                )}
              ></div>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="subtitle1">Assessments</Typography>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  summary.result[0].assessment
                )}
              ></div>
            </Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="subtitle1">Post/Supervision</Typography>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  summary.result[0].postSupervision
                )}
              ></div>
            </Box>
            <SessionReportTasks id={id} />
            <Paper
              variant="outlined"
              sx={{ padding: "1rem", borderRadius: ".5rem" }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" component="span">
                    Status:
                  </Typography>
                  <Typography variant="body2" component="span">
                    <span
                      style={{
                        color:
                          summary.result[0].status === "Approved" ||
                          summary.result[0].status === "Submitted"
                            ? "green"
                            : summary.result[0].status === "Pending"
                            ? "orange"
                            : ""
                      }}
                    >
                      {" " + summary.result[0].status}{" "}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" component="span">
                    Created by:
                  </Typography>{" "}
                  <Typography variant="body2" component="span">
                    {summary.result[0].createdBy}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Typography variant="subtitle2" component="span">
                    Created date:
                  </Typography>{" "}
                  <Typography variant="body2" component="span">
                    {formatDate(summary.result[0]?.createdDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" component="span">
                    Submitted by:
                  </Typography>
                  <Typography variant="body2" component="span">
                    {" "}
                    {summary.result[0].submittedByFirstName}{" "}
                    {summary.result[0].submittedByLastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Typography variant="subtitle2" component="span">
                    Submitted date:
                  </Typography>
                  <Typography variant="body2" component="span">
                    {" "}
                    {formatDate(summary.result[0]?.dateSubmitted)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" component="span">
                    Approved by:
                  </Typography>{" "}
                  <Typography variant="body2" component="span">
                    {summary.result[0].approvedBy}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <Typography variant="subtitle2" component="span">
                    Approved date:
                  </Typography>{" "}
                  <Typography variant="body2" component="span">
                    {formatDate(summary.result[0]?.approvedDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </>
        </Box>
      </div>
    </Page>
  );
};

export default SessionReport;
