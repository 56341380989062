import { AxiosInstance, AxiosResponse } from "axios";
import { ApiResponse, BecomeAccreditedResponse } from "types/Api";
import {
  BecomeAccreditedAccreditationDetails,
  BecomeAccreditedFinaliseAccount,
  BecomeAccreditedHealthServiceDetails,
  BecomeAccreditedPersonalDetails
} from "types/becomeAccredited";
import { ACCREDITATION_CATEGORIES } from "utils/lists/becomeAccredited/accreditationCategories";
import { ACCREDITATION_TYPES } from "utils/lists/becomeAccredited/accreditationTypes";
import { POSITIONS } from "utils/lists/positions";

function becomeAccredited(
  data: {
    personalDetails: BecomeAccreditedPersonalDetails;
    accreditationDetails: BecomeAccreditedAccreditationDetails;
    healthServiceDetails: BecomeAccreditedHealthServiceDetails;
    accountDetails: BecomeAccreditedFinaliseAccount;
  },
  axiosInstance: AxiosInstance,
  onSubmitFinish?: (wasProcessSuccessful: boolean) => void,
  onTrainingPostActiveAccreditationExists?: (exists: boolean) => void
) {
  axiosInstance
    .post<ApiResponse<BecomeAccreditedResponse>>("/BecomeAccredited", {
      personalDetails: {
        title: data.personalDetails.title,
        firstName: data.personalDetails.firstName,
        lastName: data.personalDetails.lastName,
        position: data.personalDetails.position
      },
      accreditationDetails: {
        accreditationType: data.accreditationDetails.accreditationType,
        accreditationCategory:
          data.accreditationDetails.accreditationCategory ===
          ACCREDITATION_CATEGORIES.BOTH.value
            ? [
                ACCREDITATION_CATEGORIES.CORE_GENERALIST.value,
                ACCREDITATION_CATEGORIES.AST.value
              ]
            : [data.accreditationDetails.accreditationCategory],
        astDiscipline: data.accreditationDetails.astDiscipline,
        reasons: data.accountDetails.reasons
      },
      healthServiceDetails: data.healthServiceDetails
        ? {
            companyId: data.healthServiceDetails.companyId
              ? data.healthServiceDetails.companyId
              : null,
            name: data.healthServiceDetails.name,
            type: data.healthServiceDetails.type,
            streetAddress: data.healthServiceDetails.streetAddress,
            suburb: data.healthServiceDetails.suburb,
            state: data.healthServiceDetails.state,
            postCode: data.healthServiceDetails.postcode,
            phoneNumber: data.healthServiceDetails.phoneNumber,
            modifiedMonash: `MMM ${data.healthServiceDetails.modifiedMonash}`,
            prepareProspects: data.healthServiceDetails.prepareProspects
          }
        : null
    })
    .then((response: AxiosResponse<ApiResponse<BecomeAccreditedResponse>>) => {
      if (response.status === 200 || response.status === 201) {
        if (response.data.result.accreditationCreated === false) {
          if (onTrainingPostActiveAccreditationExists) {
            onTrainingPostActiveAccreditationExists(true);
          }
        }
        if (
          data.accreditationDetails.accreditationType ===
            ACCREDITATION_TYPES.TRAINING_POST.value &&
          data.personalDetails.position !== POSITIONS.PRACTICE_MANAGER.value
        ) {
          const practiceManagerRequest = {
            ...data.personalDetails.practiceManager,
            function: "Practice Manager",
            companyId: response.data.result.companyId,
            accreditationApplicationIds: response.data.result.accreditationIds
          };
          axiosInstance
            .post(
              "/Person/CreatePersonWithCompanyFunctionWebUser",
              practiceManagerRequest
            )
            .then((personResponse: AxiosResponse<ApiResponse<any>>) => {
              if (
                personResponse.status === 200 ||
                personResponse.status === 201
              ) {
                if (onSubmitFinish) {
                  onSubmitFinish(true);
                }
              } else {
                if (onSubmitFinish) {
                  onSubmitFinish(false);
                }
                throw new Error(
                  response.status +
                    " error creating practice manager: " +
                    response.data?.message
                );
              }
            })
            .catch((err) => {
              if (onSubmitFinish) {
                onSubmitFinish(false);
              }
              throw err;
            });
        } else {
          if (onSubmitFinish) {
            onSubmitFinish(true);
          }
        }
      } else {
        if (onSubmitFinish) {
          onSubmitFinish(false);
        }
        throw new Error(
          response.status +
            " error becoming accredited: " +
            response.data?.message
        );
      }
    })
    .catch((err) => {
      if (onSubmitFinish) {
        onSubmitFinish(false);
      }
      throw err;
    });
}

export { becomeAccredited };
