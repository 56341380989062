import { ReactElement } from "react";
import { Paper, TableContainer, TableContainerProps } from "@mui/material";

const StyledTableContainer = (props: TableContainerProps): ReactElement => {
  const { children } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "0px 0px 3px 1px rgb(0 0 0 / 20%)" }}
      {...props}
    >
      {children}
    </TableContainer>
  );
};

export default StyledTableContainer;
