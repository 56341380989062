import { useAxios } from "./axios";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export type AxiosRequest = AxiosRequestConfig | null | undefined;

// TODO: add request cancellation just like Axios
// https://github.com/vercel/swr/issues/129#issuecomment-786787397

export interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    "isValidating" | "error" | "mutate"
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    "fallbackData"
  > {
  fallbackData?: Data;
}

export default function useRequest<Data = unknown, Error = unknown>(
  request: AxiosRequest,
  { fallbackData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  const { axiosInstance } = useAxios();

  const {
    data: response,
    error,
    isValidating,
    mutate
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    // TODO: you can add ${getConfig().apiBaseUrl} here
    request ? JSON.stringify(request) : null,
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    async () => {
      return axiosInstance.request<Data>(request!);
    },
    {
      onErrorRetry: (error, _key, _config, revalidate, { retryCount }) => {
        if (error.response?.status === 404) {
          return;
        }

        if (retryCount > 2) {
          return;
        }

        setTimeout(() => revalidate({ retryCount }), 5000);
      },
      ...config,
      fallbackData: fallbackData && {
        status: 200,
        statusText: "InitialData",
        config: request!,
        headers: {},
        data: fallbackData
      }
    }
  );

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    mutate
  };
}
