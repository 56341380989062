import stableSort from "./stableSort";

export function ascendingComparator(a: string, b: string) {
  return (a ?? "").localeCompare(b ?? "");
}

export function descendingComparator(a: string, b: string) {
  return -(a ?? "").localeCompare(b ?? "");
}

export function sortAscending(array: string[]): string[] {
  return stableSort(array, ascendingComparator);
}

export function sortDescending(array: string[]): string[] {
  return stableSort(array, descendingComparator);
}
