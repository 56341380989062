function setItemLocalStorage(
  localStorageKey: string,
  localStorageIndex: string,
  localStorageValue: string
): void {
  localStorage.setItem(
    `${localStorageKey}|${localStorageIndex}`,
    localStorageValue
  );
}

function getItemLocalStorage(
  localStorageKey: string,
  localStorageIndex: string
): string | null {
  return localStorage.getItem(`${localStorageKey}|${localStorageIndex}`);
}

function removeItemLocalStorage(
  localStorageKey: string,
  localStorageIndex: string
): void {
  localStorage.removeItem(`${localStorageKey}|${localStorageIndex}`);
}

export { getItemLocalStorage, removeItemLocalStorage, setItemLocalStorage };
