import { CurriculumApplicationRotation } from "../types/Api/RegistrarDetails";
import dayjs from "dayjs";

export function supervisesCurrentRotation(
  curriculumApplicationRotations: CurriculumApplicationRotation[],
  currentCompanyId: number,
  personId: number
): boolean {
  const currentDate = dayjs();
  const supervisesCurrentRotation =
    curriculumApplicationRotations &&
    curriculumApplicationRotations.length > 0 &&
    curriculumApplicationRotations.some(
      (r) =>
        r.companyId === currentCompanyId &&
        r.supervisorID === personId &&
        (!r.startDate ||
          dayjs(r.startDate).year() === 1900 ||
          dayjs(r.startDate).isBefore(currentDate, "d") ||
          dayjs(r.startDate).isSame(currentDate, "d")) &&
        (!r.endDate ||
          dayjs(r.endDate).year() === 1900 ||
          dayjs(r.endDate).isSame(currentDate, "d") ||
          dayjs(r.endDate).isAfter(currentDate, "d"))
    );
  return supervisesCurrentRotation;
}

export function companyHasCurrentRotations(
  curriculumApplicationRotation: CurriculumApplicationRotation[],
  currentCompanyId: number
): boolean {
  const currentDate = dayjs();
  const companyHasCurrentRotation =
    curriculumApplicationRotation &&
    curriculumApplicationRotation.length > 0 &&
    curriculumApplicationRotation.some(
      (r) =>
        r.companyId === currentCompanyId &&
        (!r.startDate ||
          dayjs(r.startDate).year() === 1900 ||
          dayjs(r.startDate).isBefore(currentDate, "d") ||
          dayjs(r.startDate).isSame(currentDate, "d")) &&
        (!r.endDate ||
          dayjs(r.endDate).year() === 1900 ||
          dayjs(r.endDate).isSame(currentDate, "d") ||
          dayjs(r.endDate).isAfter(currentDate, "d"))
    );
  return companyHasCurrentRotation;
}

export function companyHasApprovedOrCompletedRotations(
  curriculumApplicationRotation: CurriculumApplicationRotation[],
  currentCompanyId: number
): boolean {
  const companyHasApprovedOrCompletedRotations =
    curriculumApplicationRotation &&
    curriculumApplicationRotation.length > 0 &&
    curriculumApplicationRotation.some(
      (r) =>
        r.companyId === currentCompanyId &&
        (r.status === "Approved" || r.status === "Completed")
    );
  return companyHasApprovedOrCompletedRotations;
}

export function companyHasApprovedAccreditationApplication(
  accreditationApplicationStatus: string
): boolean {
  const companyHasApprovedAccreditationApplication =
    accreditationApplicationStatus &&
    (accreditationApplicationStatus.toLowerCase().trim() === "approved" ||
      accreditationApplicationStatus.toLowerCase().trim() ===
        "approved provisional");
  return companyHasApprovedAccreditationApplication;
}

export function hasApprovedOrCompletedRotation(
  curriculumApplicationRotation: CurriculumApplicationRotation[],
  currentCompanyId: number,
  personId: number
): boolean {
  const hasApprovedOrCompletedRotation =
    curriculumApplicationRotation &&
    curriculumApplicationRotation.length > 0 &&
    curriculumApplicationRotation.some(
      (r) =>
        r.companyId === currentCompanyId &&
        r.supervisorID === personId &&
        (r.status.toLowerCase().trim() === "approved" ||
          r.status.toLowerCase().trim() === "completed")
    );

  return hasApprovedOrCompletedRotation;
}

export function registrarHasCurrentRotation(
  registrarId: number,
  curriculumApplicationRotation: CurriculumApplicationRotation[],
  currentCompanyId: number
): boolean {
  const currentDate = dayjs();
  const registrarHasCurrentRotation =
    curriculumApplicationRotation &&
    curriculumApplicationRotation.length > 0 &&
    curriculumApplicationRotation.some(
      (r) =>
        r.companyId === currentCompanyId &&
        r.personId === registrarId &&
        (!r.startDate ||
          dayjs(r.startDate).year() === 1900 ||
          dayjs(r.startDate).isBefore(currentDate, "d") ||
          dayjs(r.startDate).isSame(currentDate, "d")) &&
        (!r.endDate ||
          dayjs(r.endDate).year() === 1900 ||
          dayjs(r.endDate).isSame(currentDate, "d") ||
          dayjs(r.endDate).isAfter(currentDate, "d"))
    );
  return registrarHasCurrentRotation;
}

export function supervisesRegistrarOnCurrentRotation(
  registrarId: number,
  curriculumApplicationRotation: CurriculumApplicationRotation[],
  currentCompanyId: number,
  personId: number
): boolean {
  const currentDate = dayjs();
  const supervisesCurrentRotation =
    curriculumApplicationRotation &&
    curriculumApplicationRotation.length > 0 &&
    curriculumApplicationRotation.some(
      (r) =>
        r.companyId === currentCompanyId &&
        r.personId === registrarId &&
        r.supervisorID === personId &&
        (!r.startDate ||
          dayjs(r.startDate).year() === 1900 ||
          dayjs(r.startDate).isBefore(currentDate, "d") ||
          dayjs(r.startDate).isSame(currentDate, "d")) &&
        (!r.endDate ||
          dayjs(r.endDate).year() === 1900 ||
          dayjs(r.endDate).isSame(currentDate, "d") ||
          dayjs(r.endDate).isAfter(currentDate, "d"))
    );
  return supervisesCurrentRotation;
}
