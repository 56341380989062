import { useLogout } from "../../hooks/useLogout";
import { Button } from "@mui/material";
import type { FC } from "react";

const LogoutButton: FC = () => {
  const { logout } = useLogout();

  return <Button onClick={() => logout()}>Log out</Button>;
};

export default LogoutButton;
