import * as modules from "../../../modules";

export const EduRequirementHeading = [
  "Requirement",
  "Status",
  "Required",
  "Date Planned",
  "Completed",
  "Date Completed"
];
export const trainingRequirementHeading = [
  "Requirement",
  "Status",
  "RPL",
  "Required",
  "Date Planned",
  "Date Completed",
  "Location",
  "State",
  "MMM",
  "Start Date",
  "End Date",
  "FTE",
  "Period"
];
export const EmergencyCoursesHeading = [
  "Name",
  "Status",
  "Date Registered",
  "Start Date",
  "Date Completed"
];
export const PlacementHistoryHeading = [
  "Post Name",
  "State",
  "Town/City",
  "Postcode",
  "MMM",
  "Status",
  "Start date",
  "End date",
  "Period completed",
  "FTE",
  "Primary Care Term",
  "Requirements covered",
  "Supervisor"
];

export const astRequirementHeading = [
  "Requirement",
  "Status",
  "RPL",
  "Requirement Type",
  "Required",
  "Date Planned",
  "Completed",
  "Date Completed"
];

export const EducationParentIDList = [226, 227, 228, 231, 305];
export const dateFormats = {
  normalDate: "dd/MM/yyyy",
  keyboardDate: "dd/MM/yyyy"
};
export const registrarDetailModule = modules.modules.find(
  (i) => i.name === "Registrars"
);
export const cgtAssessmentRequirementHeading = [
  "Requirement",
  "Status",
  "RPL",
  "Required",
  "Date Planned",
  "Completed",
  "Date Completed",
  "Attempts History"
];
export const cgtAssessmentPrepAndSupportHeading = [
  "Activity Name",
  "Status",
  "Start Date",
  "Date Completed"
];
export const meSessionDetailsHeading = [
  "Session",
  "Status",
  "Medical Educator",
  "Date",
  "Time",
  "Report Link"
];
export const meSessionDetailsReportsHeading = [
  "Task name",
  "Created By",
  "Assigned to",
  "Date Created",
  "Priority",
  "Status"
];
export const meSessionReportsHeading = [
  "Registrar Name",
  "Session Type",
  "Session Date/Time",
  "Created By",
  "Date Created",
  "Status",
  "Action"
];
export enum breadCrumbText {
  progression = "Training Plan & Progression",
  mesessiondetails = "ME Session History",
  cgtassessment = "CGT Assessment",
  cgttraining = "CGT Training",
  cgteducation = "CGT Education",
  assessmentprepsupport = "Assessment Prep & Support",
  astrequirements = "AST",
  placementhistory = "Placement History",
  logbooksummary = "Logbook Summary",
  contacthistory = "Contact History",
  registrars = "Registrars",
  logbookentries = "Logbook Entries",
  mesessionreportssummaries = "ME Session Summary Report",
  report = "Report",
  documents = "Documents",
  sessionreports = "Session Reports",
  mesessionreport = "ME Session Report",
  create = "Create Session Report",
  addsessionsummary = "Add Session Summary",
  addtasks = "Add Tasks",
  edit = "Edit Session Report",
  actionitems = "Action Items"
}

export const logbookEntriesHeading = [
  "Procedure name",
  "Completion",
  "Level required",
  "Level achieved",
  "Where performed",
  "Date performed",
  "Certifier details",
  "Status"
];

export const actionItemHeading = [
  "Item",
  "Assigned by",
  "Due Date",
  "Priority",
  "Status",
  "Action"
];

export const SessionReportTaskHeading = [
  "Task",
  "Created by",
  "Assigned to",
  "Due Date",
  "Priority",
  "Status",
  "Action"
];

export const SessionReportViewTaskHeading = [
  "Task",
  "Created by",
  "Assigned to",
  "Due date",
  "Priority",
  "Status"
];
