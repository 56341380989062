import React from "react";
import NavItem from "./NavItem";
import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import type { FC, ReactNode } from "react";

export interface Item {
  path?: string;
  icon?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  component?: React.ElementType;
}

export interface NavSectionProps extends ListProps {
  items: Item[];
  currentPath: string;
  title?: string;
  drawerOpen?: boolean;
  component?: React.ElementType;
}

const renderNavItems = ({
  depth = 0,
  items,
  currentPath,
  drawerOpen,
  component
}: {
  items: Item[];
  currentPath: string;
  depth?: number;
  drawerOpen?: boolean;
  component?: React.ElementType;
}): ReactNode => (
  <List disablePadding>
    {items.reduce(
      (acc, item) =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        reduceChildRoutes({
          acc,
          item,
          currentPath,
          depth,
          drawerOpen,
          component
        }),
      [] as React.ReactElement[]
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  currentPath,
  item,
  depth,
  drawerOpen,
  component
}: {
  acc: ReactNode[];
  currentPath: string;
  item: Item;
  depth: number;
  drawerOpen?: boolean;
  component?: React.ElementType;
}): Array<ReactNode> => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path === currentPath;

  if (item.children) {
    const partialMatch = currentPath.includes(item.path);

    acc.push(
      <NavItem
        drawerOpen={drawerOpen}
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        openprop={partialMatch}
        path={item.path}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          currentPath,
          drawerOpen,
          component
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        drawerOpen={drawerOpen}
        openprop={exactMatch}
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        component={item.component ?? component}
      />
    );
  }

  return acc;
};

const NavSection: FC<NavSectionProps> = (props) => {
  const { items, currentPath, drawerOpen, title, component, ...other } = props;

  return (
    <>
      <List
        sx={[
          {
            padding: {
              xs: "1rem",
              sm: "0rem",
              md: "0rem",
              lg: "1rem"
            }
          }
        ]}
        subheader={
          title && (
            <ListSubheader
              disableGutters
              disableSticky
              sx={{
                textAlign: "center",
                color: "text.primary",
                fontSize: "0.75rem",
                lineHeight: 2.5,
                fontWeight: 700,
                textTransform: "uppercase",
                ...(drawerOpen && { textAlign: "center" })
              }}
            >
              {title}
            </ListSubheader>
          )
        }
        {...other}
      >
        {renderNavItems({
          items,
          currentPath,
          drawerOpen,
          component
        })}
      </List>
    </>
  );
};

export default NavSection;
