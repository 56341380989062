import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";
import { breadCrumbText } from "components/pages/registrars/Constants";
import { Link as RouterLink, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x: any) => x);
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "1rem" }}>
      {pathnames.length > 0 ? (
        <Link
          variant="body2"
          underline="hover"
          color="inherit"
          to={"/dashboard"}
          sx={{ textTransform: "capitalize" }}
          component={RouterLink}
        >
          Home
        </Link>
      ) : (
        <Typography variant="body2">Home</Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        if (parseInt(name)) name = "Profile " + name;
        else {
          if (name.indexOf("-") !== -1) {
            name = name.replaceAll("-", "");
          }
          name = breadCrumbText[name];
        }
        return isLast ? (
          <Typography
            key={name}
            sx={{ textTransform: "capitalize" }}
            variant="body2"
            color="text.primary"
          >
            {name}
          </Typography>
        ) : (
          <Link
            underline="hover"
            color="inherit"
            key={name}
            to={routeTo}
            sx={{ textTransform: "capitalize" }}
            variant="body2"
            component={RouterLink}
          >
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
