import { useRef, useState } from "react";
import { useLogout } from "../../../../hooks/useLogout";
import { useAuth0 } from "@auth0/auth0-react";
import ExpandLessTwoToneIcon from "@mui/icons-material/ExpandLessTwoTone";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Avatar,
  Box,
  Button,
  Hidden,
  Popover,
  Typography
} from "@mui/material";
import grey from "@mui/material/colors/grey";
import { styled } from "@mui/material/styles";
import { NavLink as RouterLink } from "react-router-dom";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding: ${theme.spacing(0, 1)};
        height: ${theme.spacing(7)};
        background-color: transparent;
        color: ${theme.palette.primary.main};
        box-shadow: none;
        :hover {
            background-color: transparent;
        }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: white;
        padding: ${theme.spacing(2, 2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        display: block;     
`
);

// eslint-disable-next-line no-undef
function HeaderUserbox(): JSX.Element {
  const { user } = useAuth0();
  const { logout } = useLogout();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton ref={ref} onClick={handleOpen}>
        <Avatar
          alt={`${user.given_name} ${user.family_name}`}
          src={`${user.given_name[0]}${user.family_name[0]}`}
          sx={{ color: "white" }}
        />
        <Hidden lgDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{`${user.given_name} ${user.family_name}`}</UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Hidden mdDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} color={"disabled"} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        style={{ borderRadius: "8px" }}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MenuUserBox sx={{ minWidth: 210, padding: 2 }} display="flex">
          <Avatar
            alt={`${user.given_name} ${user.family_name}`}
            src={`${user.given_name[0]}${user.family_name[0]}`}
          />
          <UserBoxText>
            <UserBoxLabel
              variant="body1"
              sx={{ paddingTop: "8px" }}
            >{`${user.given_name} ${user.family_name}`}</UserBoxLabel>
          </UserBoxText>
          <ExpandLessTwoToneIcon
            sx={{ marginLeft: "auto" }}
            onClick={handleClose}
            color={"disabled"}
          />
        </MenuUserBox>
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2 }}>
          <Box sx={{ justifyContent: "left" }}>
            <Button
              variant="text"
              component={RouterLink}
              to="/my-settings"
              endIcon={<SettingsIcon />}
              sx={{ color: grey[500] }}
              fullWidth
            >
              My Settings
            </Button>
          </Box>
          <Box>
            <Button
              onClick={logout}
              sx={{ color: grey[500] }}
              variant="text"
              fullWidth
            >
              Log out
              <LogoutTwoToneIcon sx={{ ml: 1 }} />
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
