import Stripe from "../../../img/construction-stripe.png";
// TODO: when DesignSystem gets updated, this image can be removed
import UluruBG from "../../../img/vladimir-haltakov-Efm5R4YroH4-unsplash.jpg";
import { ReactComponent as LogoSVG } from "../../atoms/logo.svg";
import { HandymanOutlined } from "@mui/icons-material";
import { Box, Link, Typography } from "@mui/material";
import LogoutButton from "components/molecules/LogoutButton";

const AreTheyLoggedIn = () => {
  // isAuthenticated from auth0 is unreliable and often returns false when it
  // should be true. This is a workaround to make sure we know whether a login
  // has occurred.
  const isTokeninLocalStorage = Object.keys(window.localStorage).some((el) =>
    el.includes("auth0spajs")
  );

  return isTokeninLocalStorage;
};

const ConstructionTape = () => {
  const style = {
    height: "20px",
    width: "100%",
    backgroundImage: `url(${Stripe})`,
    backgroundColor: "#000",
    backgroundSize: "40px",
    marginBottom: "30px"
  };

  return <div style={style} />;
};

const DownForMaintenance = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundSize: "cover",
        display: "flex",
        backgroundImage: `url("${UluruBG}")`
      }}
    >
      <Box
        sx={{
          background: "white",
          width: { xs: "100vw", md: "900px" }
        }}
      >
        <Box
          sx={{
            padding: "2.5rem 1rem",
            boxSizing: "content-box",
            textAlign: "center"
          }}
        >
          <Box
            sx={{ maxWidth: "350px", margin: "0 auto", marginBottom: "30px" }}
          >
            <LogoSVG />
          </Box>
          <Typography variant="h3" sx={{ marginBottom: "30px" }}>
            Down for Maintenance
          </Typography>
          <Typography
            component="p"
            variant="body1"
            sx={{
              maxWidth: "630px",
              margin: "0 auto",
              padding: {
                xs: "0 2rem",
                md: "0"
              },
              marginBottom: "30px"
            }}
          >
            Unfortunately, the portal is down for maintenance at this time. This
            is to ensure the platform remains stable and secure for our
            partners. We apologise for any inconvenience this may cause. If you
            have any questions, please contact{" "}
            <Link
              href="https://mycollege.acrrm.org.au/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ whiteSpace: "nowrap" }}
            >
              ACRRM support
            </Link>
            .
          </Typography>
          {AreTheyLoggedIn() ? (
            <LogoutButton />
          ) : (
            <HandymanOutlined
              sx={{
                fontSize: "50px",
                color: "#616161",
                margin: "0 auto",
                marginBottom: "20px"
              }}
            />
          )}
        </Box>
        <ConstructionTape />
      </Box>
    </Box>
  );
};

export default DownForMaintenance;
