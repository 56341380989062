import stableSort from "./stableSort";
import dayjs from "dayjs";

export function ascendingComparator(a: string | Date, b: string | Date) {
  if (!a) {
    a = "1900-01-01";
  }
  if (!b) {
    b = "1900-01-01";
  }
  const aDate = dayjs(a);
  const bDate = dayjs(b);

  if (aDate.isBefore(bDate)) {
    return -1;
  }
  if (aDate.isAfter(bDate)) {
    return 1;
  }
  return 0;
}

export function descendingComparator(a: string | Date, b: string | Date) {
  if (!a) {
    a = "1900-01-01";
  }
  if (!b) {
    b = "1900-01-01";
  }
  const aDate = dayjs(a);
  const bDate = dayjs(b);

  if (aDate.isBefore(bDate)) {
    return 1;
  }
  if (aDate.isAfter(bDate)) {
    return -1;
  }
  return 0;
}

export function sortAscending(array: string[] | Date[]): string[] | Date[] {
  return stableSort(array, ascendingComparator);
}

export function sortDescending(array: string[] | Date[]): string[] | Date[] {
  return stableSort(array, descendingComparator);
}
