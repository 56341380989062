import { accreditationApplicationQuestions } from "../../../../data/accreditationApplicationQuestions.json";

export type PreviouslyUploadedFile = {
  // SharePoint ID of the file
  fileId: string;

  // filename in Sharepoint
  // e.g. 11505-Accreditation-Certificate-20220127105911-0.pdf
  fileName: string;

  // mimetype of the file
  fileContentType: string;

  // size of the file in bytes
  fileSize: number;

  // question id of the picker that uploaded the file
  // e.g. accreditationApplication.accreditationsHeld.accreditationCertificate
  // accreditationApplication.additionalSupervisors.supervisors.fellowshipCertificate
  clientFileId: string;

  // Unique identifier of the file picker the file was uploaded to
  // e.g., accreditationApplication.additionalSupervisors.supervisors[key123]fellowshipCertificate
  filePickerId: string;

  // Id of the entity this document is attached to.
  entityRecordId: string;
};

export interface FileHasMetadata {
  // ISO string
  dateTimeUploaded: string;

  // question id of the picker the file was dropped onto
  // e.g., accreditationApplication.accreditationsHeld.accreditationCertificate
  // e.g., accreditationApplication.additionalSupervisors.supervisors.fellowshipCertificate
  targetQuestionId: string;

  // Unique identifier of the file picker the file was uploaded to
  // e.g., accreditationApplication.accreditationsHeld.accreditationCertificate
  // e.g., accreditationApplication.additionalSupervisors.supervisors[key123].fellowshipCertificate
  filePickerId: string;

  // this is the File
  file: File;

  // if react-dropzone reports validation errors, they're here
  errors?: {
    // e.g. file-too-small
    code: string;

    // e.g. File is smaller than 1 bytes (the number is dependent on config in
    // FilePicker)
    message: string;
  }[];
}

export type NewOrPreviousFile = PreviouslyUploadedFile | FileHasMetadata;

export interface FileUploadRecord {
  fileName: string;

  // also record the dateTimeUploaded from FileHasMetadata - it's an ISO string
  // "2022-02-02T01:42:33.003Z"
  dateTimeUploaded: string;

  // has axios thrown an error?
  axiosError?: boolean;

  // the id of the file from SharePoint
  // "01F3AJNRL7XV3AMXPPOZGKBBLSAHCXJJ3Y"
  // FileRecords with fileIds can be considered successful uploads
  fileId?: string;

  // Whether or not the file is in the process of being completed
  isUploading: boolean;

  /* The file upload process looks like this:
   *
   * Pre-upload (dropped, but the application is NOT submitting)
   * { isUploading: false, dateTimeUploaded: "xxx", fileName: "zzz" }
   *
   * Files that fail validation do not create an upload record.
   *
   * Uploading (dropped, and the application IS submitting)
   * { isUploading: true, dateTimeUploaded: "xxx", fileName: "zzz" }
   *
   * Uploaded (dropped, and the application has submitted)
   * { isUploading: false, dateTimeUploaded: "xxx", fileName: "zzz", fileId: "yyy" }
   */
}

export function isPreviouslyUploadedFile(
  file: NewOrPreviousFile
): file is PreviouslyUploadedFile {
  return (file as PreviouslyUploadedFile).fileName !== undefined;
}

export function lookForHTMLWithDataAttrs(
  beenClicked: HTMLElement
): HTMLElement | undefined {
  // what's been clicked (the trash icon)
  let foundDataAttribute: HTMLElement | undefined;

  // from the trash can, climb up the DOM until we find an element
  // with the data-filename attribute
  while (!foundDataAttribute) {
    if (beenClicked.dataset.filename || beenClicked.dataset.datetimeuploaded) {
      foundDataAttribute = beenClicked;
      break;
    }

    // do not go outside FilePicker; if we do, something's wrong
    if (beenClicked.dataset.trashLimit === "true") {
      break;
    }

    beenClicked = beenClicked.parentElement;
  }

  if (!foundDataAttribute) {
    if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
      console.error("Error climbing the DOM tree");
    }

    throw new Error("Error climbing the DOM tree");
  }

  return foundDataAttribute;
}

/**
 * Performs a lookup to get the section from a question's long ID.
 * @param questionId The long ID of a question.
 */
export const getSection = (questionId: string) => {
  let section = "";

  accreditationApplicationQuestions.forEach((currSection) => {
    return currSection.questions.forEach((question) => {
      if (question.id === questionId) {
        return (section = currSection.section);
      }
    });
  });

  if (questionId.includes("additionalSupervisor")) {
    section = "Additional.Supervisors";
  }

  if (questionId.includes("principalSupervisor")) {
    section = "Principal.Supervisor";
  }

  if (section === "") {
    if (window.localStorage.getItem("ACRRM_DEBUG_MODE") === "true") {
      console.error("FilePicker doesn't know where this file goes", {
        questionId
      });
    }
  }

  return section.replaceAll(" ", ".");
};

export const findFileUploadRecord = (
  thisFile: FileHasMetadata,
  filesUploadState: FileUploadRecord[]
): FileUploadRecord =>
  filesUploadState.find(
    (el) =>
      el.fileName === thisFile.file.name &&
      el.dateTimeUploaded === thisFile.dateTimeUploaded
  );
