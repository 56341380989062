import { ReactNode, useContext, useEffect, useState } from "react";
import { useCompanyContext } from "../../hooks/companyContext";
import { SidebarContext } from "../../hooks/sidebarContext";
import * as modules from "../../modules";
import { canAccessModule } from "../../utils/roles";
import Header from "./Header";
import MainMenu, { SectionItem } from "./Page/MainMenu";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getConfig } from "getConfig";
import { DisconnectListener } from "hooks/disconnectContext";
import { useConfiguration } from "hooks/useFeatureFlag";
import { Module } from "types/Module";

type PageProps = {
  currentPath: string;
  children: ReactNode;
};

const MainWrapper = styled(Box)(() => ({
  flex: "1 1 auto",
  display: "flex",
  height: "100%"
}));

const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  marginTop: "80px",
  marginBottom: "1rem",
  flex: "1 1 auto",
  overflowY: "auto",
  overflowX: "hidden",

  [theme.breakpoints.up("sm")]: {
    // NOTE: not specific to the Figma, just "looks good"
    paddingLeft: "120px"
  },

  [theme.breakpoints.up("lg")]: {
    paddingLeft: "240px"
  }
}));

const Page = (props: PageProps) => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const { currentPath, children } = props;
  const {
    currentCompanyId,
    personCompanyFunctions,
    personFunctions,
    accreditationApplication,
    curriculumApplicationRotation
  } = useCompanyContext();
  const [menuItems, setMenuItems] = useState([]);
  const config = getConfig();
  const { config: removePageAccess, loading: removePageAccessLoading } =
    useConfiguration<string[]>(config.configRemovePageName);

  useEffect(() => {
    setMenuItems(getMenuItemsForUser(modules.modules as Module[]));
  }, [
    personCompanyFunctions,
    personFunctions,
    currentCompanyId,
    removePageAccess,
    removePageAccessLoading
  ]);

  function getMenuItemsForUser(modules: Module[]): SectionItem[] {
    const menuItems = [] as SectionItem[];
    if (personCompanyFunctions && !removePageAccessLoading) {
      for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        let hasPageAccess = true;
        if (removePageAccess && removePageAccess.indexOf(module.name) > -1) {
          hasPageAccess = false;
        }
        if (
          hasPageAccess &&
          canAccessModule(
            module,
            personCompanyFunctions,
            personFunctions,
            currentCompanyId,
            accreditationApplication,
            curriculumApplicationRotation
          )
        ) {
          const menuItem = {
            title: module.name,
            path: module.path,
            icon: module.icon
          } as SectionItem;
          if (module.modules && module.modules.length > 0) {
            menuItem.children = getMenuItemsForUser(module.modules);
          }
          menuItems.push(menuItem);
        }
      }
    }
    return menuItems;
  }

  return (
    <>
      <DisconnectListener />
      <div id="acrrmMainMenu">
        <MainMenu
          path={currentPath}
          menuItems={[
            {
              title: "",
              items: removePageAccessLoading ? [] : menuItems
            }
          ]}
          open={sidebarToggle}
          setOpen={toggleSidebar}
        />
      </div>
      <MainWrapper>
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            flexGrow: 1
          }}
        >
          <Header />
          <MainContent>
            <Box sx={{ m: 2.5 }}>{children}</Box>
          </MainContent>
        </Box>
      </MainWrapper>
    </>
  );
};

export default Page;
export type { PageProps };
