import React from "react";
import NavSection from "./NavSection";
import { TallLogo } from "./TallLogo";
import { WideLogo } from "./WideLogo";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { NavLink as RouterLink } from "react-router-dom";

const drawWidthFull = 240;
const drawWidthTablet = 120;

export interface SectionItem {
  title: string;
  path: string;
  icon: string;
  children?: SectionItem[];
}

export interface SectionsProps {
  title?: string;
  items: SectionItem[];
}

export interface MainMenuProps {
  window?: () => Window;
  path: string;
  menuItems: SectionsProps[];
  open: boolean;
  setOpen: (open: boolean | (() => void)) => void;
  component?: React.ElementType;
}

const MainMenu = (props: MainMenuProps) => {
  const { window, path, menuItems, open, setOpen, component } = props;
  const theme = useTheme();

  const isBetweenSMandLG = useMediaQuery(theme.breakpoints.between("sm", "lg"));

  const drawerWidth = isBetweenSMandLG ? drawWidthTablet : drawWidthFull;

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const drawer = menuItems.map((section) => (
    <div key={`drawer-${section.title}`}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
          mx: 1
        }}
        key={`box-${section.title}`}
      >
        {isBetweenSMandLG ? (
          <TallLogo height="70px" />
        ) : (
          <WideLogo height="100%" />
        )}
      </Box>

      <NavSection
        component={component}
        drawerOpen={open}
        key={`navSection-${section.title}`}
        currentPath={path}
        {...section}
      />
    </div>
  ));

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { lg: 0 } }}
    >
      <Drawer
        key={"mobile"}
        container={
          window !== undefined ? () => window().document.body : undefined
        }
        variant="temporary"
        open={useMediaQuery(theme.breakpoints.only("xs")) ? open : false}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.primary.dark,
            opacity: 0.5
          }
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth
          }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        key={"tablet"}
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth
          }
        }}
        open
      >
        {drawer}
      </Drawer>
      <Drawer
        key={"desktop"}
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth
          }
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

MainMenu.defaultProps = {
  path: "",
  menuItems: [],
  open: true,
  setOpen: () => {},
  component: RouterLink
};

export default MainMenu;
