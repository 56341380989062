/* eslint-disable no-undef */
import { TableCell } from "../atoms";
import { Typography } from "@mui/material";

const ProgressionTableCell = (props: {
  text: string | number;
}): JSX.Element => {
  const { text } = props;
  return (
    <TableCell>
      <Typography variant="body2">{text}</Typography>
    </TableCell>
  );
};

export default ProgressionTableCell;
