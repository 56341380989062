import { useBecomingAccreditedContext } from "../hooks/becomeAccreditedContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import type { FC, ReactNode } from "react";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { submittingBecomeAccredited } = useBecomingAccreditedContext();

  if (!isLoading && isAuthenticated && !submittingBecomeAccredited) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
