import useMedicalEducatorSessionReportSummary from "../../../../hooks/useMESessionDetailsReportSummary";
import { Download } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { NoItemsFound } from "components/atoms";
import PageBreadcrumbs from "components/molecules/breadcrumbs/PageBreadcrumbs";
import Loading from "components/molecules/Loading";
import SessionReport from "components/pages/SessionReport";
import Page from "components/templates/Page";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../index.css";

const RegistrarMESessionReportsSummary = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const { data: summary } = useMedicalEducatorSessionReportSummary(
    query.get("reportid")
  );
  if (!summary || summary.result.length === 0) {
    return (
      <Page currentPath="/reports/session-reports">
        <PageBreadcrumbs
          currentPage="ME Session Report"
          history={[
            {
              name: "Reports - Session Reports",
              relativePath: "/reports/session-reports",
              order: 0
            }
          ]}
        />
        <Box
          sx={{
            display: "flex",
            alignContent: "baseline",
            marginBottom: "1rem"
          }}
        >
          <div
            style={{
              fontFamily: "Helvetica",
              fontSize: "1.5rem",
              color: "#3C5E5B",
              lineHeight: "1.4",
              fontWeight: "bold",
              marginTop: "3px"
            }}
          >
            <Typography variant="h2">ME Session Report</Typography>
          </div>
          <Grid>
            <Grid
              item
              sx={{ marginTop: "5px", marginLeft: "10px", marginRight: "10px" }}
            >
              <Download component="button" onClick={() => print()}></Download>
            </Grid>
          </Grid>

          <Button
            size="small"
            variant="outlined"
            onClick={() => navigate("/reports/session-reports")}
          >
            Back
          </Button>
        </Box>
        {!summary && <Loading />}
        {summary && summary.result.length === 0 && (
          <NoItemsFound>
            No ME session history report summary found.
          </NoItemsFound>
        )}
      </Page>
    );
  }
  return <SessionReport id={query.get("reportid")} sideBar="Reports" />;
};
export default RegistrarMESessionReportsSummary;
