import { getConfig } from "getConfig";
import useRequest, { Return } from "lib/useRequest";
import { ApiResponse } from "types/Api/ApiResponse";
import { RegistrarActionItemsList } from "types/Api/RegistrarActionItems";

function useSessionReportTasks(sessionReportId: string | number) {
  return useRequest<ApiResponse<RegistrarActionItemsList[]>>(
    {
      url: `${
        getConfig().apiBaseUrl
      }/Task/GetTasksLinkedToSessionReport/${sessionReportId.toString()}`
    },
    { revalidateOnFocus: false }
  ) as Return<ApiResponse<RegistrarActionItemsList[]>, unknown>;
}

export { useSessionReportTasks };
