import { FC, useContext } from "react";
import { SidebarContext } from "../../hooks/sidebarContext";
import EmptyHeader from "../templates/Header/EmptyHeader";
import MainMenu from "../templates/Page/MainMenu";
import Loading from "./Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const MainWrapper = styled(Box)(() => ({
  flex: "1 1 auto",
  display: "flex",
  height: "100%"
}));

const MainContent = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  marginTop: "80px",
  marginBottom: "1rem",
  flex: "1 1 auto",
  overflowY: "auto",
  overflowX: "hidden",

  [theme.breakpoints.up("sm")]: {
    // NOTE: not specific to the Figma, just "looks good"
    paddingLeft: "120px"
  },

  [theme.breakpoints.up("lg")]: {
    paddingLeft: "240px"
  }
}));

const LoadingScreen: FC = () => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const { isAuthenticated } = useAuth0();

  return !isAuthenticated ? (
    <b>Loading...</b>
  ) : (
    <>
      <MainMenu
        path={""}
        menuItems={[
          {
            title: "",
            items: []
          }
        ]}
        open={sidebarToggle}
        setOpen={toggleSidebar}
      />
      <MainWrapper>
        <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            flexDirection: "column",
            flexGrow: 1
          }}
        >
          <EmptyHeader />
          <MainContent>
            <Box sx={{ m: 2.5 }}>
              <Loading />
            </Box>
          </MainContent>
        </Box>
      </MainWrapper>
    </>
  );
};

export default LoadingScreen;
