import type { NameValue } from "../../../types/NameValue";

const ACCREDITATION_TYPES = {
  TRAINING_POST: {
    name: "Training Post",
    value: "Training Post",
    displayOrder: 1
  } as NameValue<string>,
  SUPERVISOR: {
    name: "Supervisor",
    value: "Supervisor",
    displayOrder: 2
  } as NameValue<string>
};

function getAccreditationTypesList(): NameValue<string>[] {
  return Object.entries(ACCREDITATION_TYPES).map(
    (i) => i[1]
  ) as NameValue<string>[];
}

export { ACCREDITATION_TYPES, getAccreditationTypesList };
