import { ReactElement } from "react";
import { theme } from "@acrrm/designsystem";
import { TableCell, TableCellProps, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const StyledTableHeaderCell = (props: TableCellProps): ReactElement => {
  const { children } = props;
  return (
    <TableCell
      sx={{
        borderLeft: "1px solid",
        borderColor: grey[300]
      }}
      {...props}
    >
      <Typography
        variant="subtitle1"
        sx={{ color: theme.palette.primary.main }}
      >
        {children}
      </Typography>
    </TableCell>
  );
};

export default StyledTableHeaderCell;
