import { ReactElement } from "react";
import { Chip, FormGroup } from "@mui/material";
import type { ChipProps } from "@mui/material";

const ChipList = (props: {
  chipData: ChipProps[];
  isFilterChip: boolean;
}): ReactElement => {
  const { chipData, isFilterChip } = props;

  return (
    <FormGroup>
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          flexWrap: "wrap",
          gap: "4px",
          padding: 0,
          margin: 0
        }}
      >
        {chipData.map((i) => {
          const backgroundColor =
            i.label === "Dual Registrar" && !isFilterChip ? "#59336D" : "";
          return (
            <li key={i.key}>
              <Chip
                {...i}
                sx={{
                  backgroundColor
                }}
              />
            </li>
          );
        })}
      </ul>
    </FormGroup>
  );
};
export default ChipList;
