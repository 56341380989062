import { ReactElement } from "react";
import Breadcrumbs, { BreadcrumbsProps } from "./Breadcrumbs";

const PageBreadcrumbs = (props: BreadcrumbsProps): ReactElement => {
  const { history, ...other } = props;

  let historyLinks = [{ name: "Home", relativePath: "/dashboard", order: -1 }];

  if (history) {
    historyLinks = historyLinks.concat(history);
  }
  return <Breadcrumbs history={historyLinks} {...other} />;
};

export default PageBreadcrumbs;
