import * as sort from ".";
import type { Order } from ".";

// Returns new sorted array
export function sortObjectArray<Key extends keyof any>(
  array: any[],
  order: Order,
  orderBy: Key,
  type?: "string" | "number" | "date"
): any[] {
  let comparator = null;
  if (type === "date") {
    comparator =
      order === "desc"
        ? sort.dateDescendingComparator
        : sort.dateAscendingComparator;
  } else if (type === "string" || typeof orderBy === "string") {
    comparator =
      order === "desc"
        ? sort.stringDescendingComparator
        : sort.stringAscendingComparator;
  } else if (type === "number" || typeof orderBy === "number") {
    comparator =
      order === "desc"
        ? sort.numberDescendingComparator
        : sort.numberAscendingComparator;
  } else {
    throw new Error("sortOjectArray.orderBy type not supported");
  }

  const objComparator = (
    a: { [key in Key]: number | string | Date },
    b: { [key in Key]: number | string | Date }
  ) => {
    return comparator(a[orderBy], b[orderBy]);
  };

  return sort.stableSort(array, objComparator);
}
