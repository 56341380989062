import { ReactElement } from "react";
import { theme } from "@acrrm/designsystem";
import { Dialog, DialogProps, useMediaQuery } from "@mui/material";

const ResponsiveDialog = (props: DialogProps): ReactElement => {
  const { children } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog {...props} fullScreen={fullScreen}>
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
